.modal {
  .close {
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: 9999;
    .icon-close {
      color: white;
      font-size: 3rem;
      @include media-breakpoint-down(xx) {
        font-size: 2rem;
       } 
    }
  }
  &-content {
    background-color: $dark;
  }
  &-dialog {
    max-width: 82rem;
  }
  &-backdrop {
    background: rgba($color: $dark-gray, $alpha: 0.9);
    &.show {
      opacity: 1;
    }
  }
}