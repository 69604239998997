@font-face {
  font-family: 'lg';
  src: url("../fonts/lg.ttf") format("truetype"), url("../fonts/lg.woff?22t19m") format("woff"), url("../fonts/lg.svg?22t19m#lg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.lg-download.lg-icon{
  display: none !important;
}
.lg-outer .lg-has-vimeo .lg-video-play {
  background: url("../images/useful/lightgallery/vimeo-play.png") no-repeat scroll 0 0 transparent;
}

.lg-outer .lg-has-vimeo:hover .lg-video-play {
  background: url("../images/useful/lightgallery/vimeo-play.png") no-repeat scroll 0 -58px transparent;
}

.lg-outer .lg-has-html5 .lg-video-play {
  background: transparent url("../images/useful/lightgallery/video-play.png") no-repeat scroll 0 0;
}

.lg-outer .lg-has-youtube .lg-video-play {
  background: url("../images/useful/lightgallery/youtube-play.png") no-repeat scroll 0 0 transparent;
}

.lg-outer .lg-has-youtube:hover .lg-video-play {
  background: url("../images/useful/lightgallery/youtube-play.png") no-repeat scroll 0 -60px transparent;
}


.lg-outer .lg-item {
  background: url("../images/useful/lightgallery/loading.gif") no-repeat scroll center center transparent;
}

.lg-backdrop.in {
  background: $dark-gray;
  opacity: 0.9;
}
.lg-toolbar {
  background: transparent;
}
.lg-toolbar .lg-icon  , .lg-actions .lg-next, .lg-actions .lg-prev{
  color: white;
}