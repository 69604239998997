.c-socials {
  display: flex;
  align-items: center;
  &__item {
    &:hover {
      .icon {
        color: $accent;
      }
    }
    &:last-child {
      .c-socials__link {
        padding-right: 0;
      }
    }
  }
  &__link {
    padding: 0 .8rem;
    .icon {
      color: white;
      font-size: 2.4rem;
      transition: all 0.3s ease;
    }
  }
  &.d-end {
    display: flex;
    justify-content: flex-end;
  }
  &--footer {
    margin-top: 1rem;
    @include media-breakpoint-down(xx) {
      display: flex;
      justify-content: center;
    }
    .icon {
      color: $dark-gray;
    }
  }
  &--menu {
    margin-top: 4rem;
  }
}