.e-section-title {
  color: $dark;
  text-transform: uppercase;
  line-height: 5rem;
  max-width: 62rem;
  margin-bottom: 0;
  font-size: 3.6rem;
  @include media-breakpoint-down(sm) {
    font-size: 3rem;
    line-height: 4rem;
  }
  @include media-breakpoint-down(xx) {
    font-size: 2.4rem;
    line-height: 3rem;
  }
  &.t-center {
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
  &.pt100 {
    padding-top: 10rem;
    @include media-breakpoint-down(sm) {
      padding-top: 8rem;
    }
    @include media-breakpoint-down(xs) {
      padding-top: 6rem;
    }
    @include media-breakpoint-down(xx) {
      padding-top: 4rem;
    }
  }
  &.pt80 {
    padding-top: 8rem;
    @include media-breakpoint-down(sm) {
      padding-top: 6rem;
    }
    @include media-breakpoint-down(xs) {
      padding-top: 4rem;
    }
    @include media-breakpoint-down(xx) {
      padding-top: 2rem;
    }
  }
  &.pt40 {
    padding-top: 4rem;
  }
  &.pb40 {
    padding-bottom: 4rem;
    @include media-breakpoint-down(xx) {
      padding-bottom: 2rem;
    }
  }
  &.accent {
    color: $accent;
  }
}