.c-404 {
  padding: 14rem 0 7rem;
  @include media-breakpoint-down(xs) {
    padding: 4rem 0;
  }
  &__inner {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative;
    &:before {
      content: attr(data-text);
      position: absolute;
      top: -7rem;
      left: 36rem;
      font-size: 14.4rem;
      color: $dark-gray;
      opacity: 0.07;
      font-weight: 900;
      text-transform: uppercase;
      line-height: normal;
      letter-spacing: -1.2px;
      white-space: nowrap;
      @include media-breakpoint-down(md) {
        font-size: 10rem;
        opacity: 0.03;
      }
    }
    &:after {
      content: attr(data-text);
      position: absolute;
      bottom: 1rem;
      left: -99rem;
      font-size: 14.4rem;
      color: $dark-gray;
      opacity: 0.07;
      font-weight: 900;
      text-transform: uppercase;
      line-height: normal;
      white-space: nowrap;
      @include media-breakpoint-down(md) {
        font-size: 10rem;
        opacity: 0.03;
      }
    }
    img {
      position: relative;
    }
    .btn {
      margin-top: 10rem;
      padding: 1.85rem 7.9rem;
      position: relative;
      z-index: 5;
    }
  }
}