.c-certificates {
  margin-bottom: 8rem;
  @include media-breakpoint-down(xs) {
    margin-bottom: 4rem;
  }
  &-item {
    position: relative;
    @include media-breakpoint-down(xs) {
      margin-bottom: 2rem;
    }
    &__inner {
      position: relative;
      img {
        width: 100%;
      }
    }
    .demo-gallery-poster {
      position: absolute;
      top: 2rem;
      right: 3.5rem;
      .icon {
        font-size: 2.4rem;
      }
    }
  }
}