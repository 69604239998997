button{
	// button reset
	display: inline-block;
	margin: 0;
	padding: 0;
	border: 0;
	background-color: transparent;
	&:hover , &:focus , &:active {
			outline: none;
	}
}
.btn{
	display: inline-block;
	font-weight: 500;
	color: inherit;
	transition: all 0.3s ease;
	text-transform: uppercase;
	font-size: 1.6rem;
	letter-spacing: normal;
	line-height: normal;
	color: white;
	@include media-breakpoint-down(xx) {
		font-size: 1.4rem;
		font-weight: 400;
	}
	&--accent {
		background-color: $accent;
		padding: 1.85rem 4.9rem;
		@include media-breakpoint-down(xx) {
			padding: 1.5rem 2rem;
		}
		&:hover {
			background-color: darken($color: $accent, $amount: 5%);
			color: white;
		}
	}
	&--outline {
		border: .2rem solid white;
		padding: 1.75rem 3.5rem;
		@include media-breakpoint-down(xx) {
			padding: 1.4rem 2rem;
		}
		&:hover {
			border: .2rem solid $accent;
			color: white;
		}
	}
	&--ico {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		.icon {
			margin-left: 1.2rem;
			color: white;
			transition: all 0.3s ease;
		}
	}
}