.c-clients {
  padding-bottom: 10rem;
  &__inner {
    display: flex;
    flex-wrap: wrap;
    margin-top: 8.5rem;
    margin-bottom: -6rem;
    @include media-breakpoint-down(sm) {
      margin-top: 4rem;
    }
    @include media-breakpoint-down(xx) {
      margin-top: 2rem;
    }
  }
  &-item {
    width: 33.3333%;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-bottom: 6rem;
    transition: all 0.3s ease;
    max-height: 16rem;
    @include media-breakpoint-down(sm) {
      width: 50%;
      margin-bottom: 3rem;
    }
    @include media-breakpoint-down(xx) {
      width: 50%;
      margin-bottom: 1.5rem;
    }
    &:hover {
      filter: grayscale(100%);
    }
    &__link {
      @include media-breakpoint-down(xx) {
        padding: 2rem;
      }
    }
  }
}