.c-breadcrumbs {
  padding: 4rem 0;
  @include media-breakpoint-down(xx) {
		padding: 2rem 0;
	}
	&__list {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}
	&__item {
		color: #919191;
		@include media-breakpoint-down(xx) {
			margin-bottom: .5rem;
		}
		&:not(:last-child){
			margin-right: 1rem;
    }
    &:hover {
      .c-breadcrumbs__link , .icon {
        color: $accent;
      }
    }
	}
	&__link {
		color: $gray;
		position: relative;
		display: flex;
		align-items: center;
    transition: all 0.3s ease;
    font-weight: bold;
		font-size: 1.8rem;
		@include media-breakpoint-down(xx) {
      font-size: 1.4rem;
    }
		.icon {
      color: $gray;
			font-size: 1.6rem;
      margin-left: 1rem;
      transition: all 0.3s ease;
		}
	}
	&__txt {
    color: $dark-gray;
    font-weight: bold;
		font-size: 1.8rem;
		@include media-breakpoint-down(xx) {
      font-size: 1.4rem;
    }
	}
}