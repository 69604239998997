.c-modal-order {
  .modal-dialog {
    max-width: 75.2rem;
  }
  &__img {
    position: absolute;
    bottom: 0;
    right: 3rem;
    @include media-breakpoint-down(xs) {
      display: none;
     } 
  }
  &__content {
    padding: 9.5rem 14.5rem;
    @include media-breakpoint-down(xs) {
      padding: 4rem;
     } 
  }
  &__title {
    text-transform: uppercase;
    color: white;
    text-align: center;
    @include media-breakpoint-down(xx) {
      font-size: 2.4rem;
     } 
  }
  &__form {
    margin-top: 10rem;
    @include media-breakpoint-down(sm) {
      max-width: 100%;
      margin-top: 3rem;
    }
    .form-line {
      display: flex;
      margin-bottom: 2.7rem;
      @include media-breakpoint-down(xx) {
        flex-direction: column;
       } 
      .e-input:not(:last-child) {
        margin-right: 4rem;
        @include media-breakpoint-down(xx) {
          margin-right: 0;
          margin-bottom: 2.7rem;
         } 
      }
      .e-input .form-control {
        background-color: transparent;
        border-bottom: 2px solid white;
        color: white;
        &::placeholder{
          color: white;
        }
      }
      .half-width {
        width: 19.8rem;
        @include media-breakpoint-down(sm) {
          width: 47%;
        }
        @include media-breakpoint-down(xx) {
          width: 100%;
         } 
      }
    }
    .btn {
      margin-top: 0.7rem;
      padding: 1.85rem 5.6rem;
      @include media-breakpoint-down(xs) {
        width: 100%;
       } 
    }
  }
}