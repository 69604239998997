// * {
//   -webkit-locale: auto;
//   white-space: normal;
// }

html {
	height: 100%;
	font-size: 10px;
}
body {
	position: relative;
	min-height: 100%;
	letter-spacing: .015rem;
	font-weight: 400 !important;
	font-family: 'Raleway' , sans-serif !important;
	&.has-pt main{
		padding-top: 12.6rem;
		@include media-breakpoint-down(md) {
      padding-top: 8rem;
    }
		@include media-breakpoint-down(xx) {
      padding-top: 6rem;
    }
	}
	&.modal-open {
		padding-right: 0 !important;
	}
	&:after {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: rgba($color: #7C7C7C, $alpha: 0.7);
		opacity: 0;
		transition: all 0.3s ease;
		visibility: hidden;
		z-index: 99;
	}
	&.fixed {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		overflow-y: scroll;
		&:after {
			opacity: 1;
			visibility: visible;
		}
	}
}
img{
	max-width: 100%;
	height: auto;
}

main {
	flex-grow: 1;
}