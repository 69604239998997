.c-info-head {
  margin-bottom: 6.2rem;
  h2 {
    color: $dark;
    text-transform: uppercase;
    line-height: 5rem;
    max-width: 62rem;
    margin-bottom: 0;
    @include media-breakpoint-down(sm) {
      font-size: 3rem;
      line-height: 4rem;
    }
    @include media-breakpoint-down(xx) {
      font-size: 2.6rem;
      line-height: 3.2rem;
    }
  }
  p {
    margin-top: 4.3rem;
    font-size: 1.8rem;
    line-height: 2.9rem;
    font-weight: 500;
    max-width: 72.3rem;
    @include media-breakpoint-down(sm) {
      font-size: 1.6rem;
    }
    @include media-breakpoint-down(xx) {
      font-size: 1.4rem;
      line-height: 2.2rem;
    }
  }
  a {
    margin-top: 3.5rem;
    padding: 1.85rem 7.8rem;
  }
  &__img {
    min-height: 25rem;
    max-height: 100%;
    height: 100%;
    position: relative;
    @include media-breakpoint-down(md) {
      display: none;
    }
    img {
      width: auto;
      padding: 2rem 0;
    }
  }
}