.c-job-aid {
  padding-bottom: 9rem;
  position: relative;
  &__wrapper {
    background-color: white;
    z-index: 100;
    position: relative;
  }
  &__inner {
    margin-top: 7rem;
    position: relative;
    @include media-breakpoint-down(xx) {
      margin-top: 4rem;
    }
    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 2px;
      background-color: $dark;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
      @include media-breakpoint-down(md) {
        left: 19.8%;
        transform: none;
      }
      @include media-breakpoint-down(sm) {
        left: 28%;
      }
      @include media-breakpoint-down(xs) {
        left: 4rem;
      }
      @include media-breakpoint-down(xx) {
        left: 1rem;
      }
    }
  }
  &-item {
    display: flex;
    cursor: pointer;
    &:not(:last-child){
      padding-bottom: 13rem;
      @include media-breakpoint-down(md) {
        padding-bottom: 10rem;
      }
      @include media-breakpoint-down(sm) {
        padding-bottom: 7rem;
      }
    }
    &:first-child {
      .c-job-aid-item__img {
        margin-top: -12rem;
      }
    }
    &__img {
      flex-shrink: 0;
      max-width: 39rem;
      width: 100%;
      display: flex;
      justify-content: center;
      opacity: 0.1;
      margin-top: -5.5rem;
      transition: all 0.3s ease;
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
    &__counter {
      margin-left: 3rem;
      position: relative;
      max-width: 11.6rem;
      width: 100%;
      @include media-breakpoint-down(xs) {
        margin-left: 6rem;
      }
      @include media-breakpoint-down(xx) {
        margin-left: 3rem;
      }
    }
    &__digit {
      font-size: 9.6rem;
      line-height: 6rem;
      font-weight: bold;
      letter-spacing: 0.02em;
      color: $dark;
      opacity: 0.3;
      position: relative;
      transform: translateY(1rem);
      transition: transform 1.5s ease;
      @include media-breakpoint-down(xs) {
        font-size: 6rem;
      }
      @include media-breakpoint-down(xx) {
        font-size: 4rem;
      }
      &:after {
        content: '';
        display: inline-block;
        position: absolute;
        right: -5.8rem;
        top: 50%;
        transform: translateY(-50%);
        height: 3rem;
        width: 3rem;
        border-radius: 50%;
        background-color: $accent;
        z-index: 5;
        opacity: 0;
        visibility: hidden;
        @include media-breakpoint-down(lg) {
          right: -4.9rem;
        }
        @include media-breakpoint-down(md) {
          right: -5.2rem;
        }
        @include media-breakpoint-down(xs) {
          left: -3rem;
          height: 2rem;
          width: 2rem;
        }

      }
    }
    &__desc {
      max-width: 46rem;
      width: 100%;
      margin-left: 12.6rem;
      transform: translateX(1rem);
      transition: transform 1.5s ease;
      @include media-breakpoint-down(md) {
        max-width: 100%;
        margin-left: 10rem;
      }
      @include media-breakpoint-down(xs) {
        margin-left: 2rem;
      }
      @include media-breakpoint-down(xx) {
        display: flex;
        width: auto;
      }
    }
    &__info {
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 2.7rem;
      color: $dark;
      opacity: 0.3;
      transition: transform 0.3s ease;
      margin-bottom: 0;
      @include media-breakpoint-down(sm) {
        font-size: 1.6rem;
        line-height: 2.2rem;
      }
      @include media-breakpoint-down(xx) {
        font-size: 1.4rem;
        line-height: 2rem;
      }
    }
    &.active {
      .c-job-aid-item {
        &__img  {
          opacity: 1;
        }
        &__info {
          opacity: 1;
        }
        &__digit {
          color: $accent;
          opacity: 1;
          transform: translateY(0);
        }
        &__desc{
          transform: translateX(0);
        }
        &__digit:after{
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}