.c-card {
  &__head {
    display: flex;
    align-items: center;
    margin-top: 3rem;
    @include media-breakpoint-down(sm) {
      margin-top: 0;
    }
  }
  &__img {
    height: 38rem;
    flex-shrink: 0;
    margin-right: 6rem;
    @include media-breakpoint-down(md) {
      height: 30rem;
    }
    @include media-breakpoint-down(sm) {
      height: 36rem;
      margin: 2rem 0;
    }
    @include media-breakpoint-down(xx) {
      height: 25rem;
      margin: 2rem 0;
    }
  }
  &__info {
    p {
      margin-bottom: 4rem;
      @include media-breakpoint-down(xx) {
        font-size: 1.4rem;
        margin-bottom: 2rem;
      }
    }
  }
  &-characteristics {
    margin: 6rem 0;
    &__list {
      margin-top: 4rem;
      max-width: 55.6rem;
      width: 100%;
    }
    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:not(:last-child){
        margin-bottom: 1.8rem;
        @include media-breakpoint-down(xx) {
          margin-bottom: 1rem;
        }
      }
    }
    &__txt{
      font-weight: 500;
      @include media-breakpoint-down(xx) {
        font-size: 1.4rem;
      }
      &:first-child{
        margin-right: 3rem;
      }
    }
  }
}