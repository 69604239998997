.c-info-table {
  margin-bottom: 6.4rem;
  table {
    margin-top: 4.5rem;
    border: 2px solid #7C7C7C;
    width: 100%;
    thead {
      tr {
        background-color: $accent;
        border-bottom: 2px solid #7C7C7C;
        th {
          font-size: 1.8rem;
          font-weight: bold;
          letter-spacing: 0.02em;
          color: white;
          text-align: center;
          padding: 2.1rem;
          font-feature-settings: 'pnum' on, 'lnum' on;
          width: 33.33333333%;
          @include media-breakpoint-down(xx) {
            font-size: 1.4rem;
            font-weight: 600;
            padding: 0.6rem;
          }
          &:not(:last-child){
            border-right: 2px solid #7C7C7C;
          }
        }
      }
    }
    tbody {
      tr {
        &:not(:last-child){
          border-bottom: 2px solid #7C7C7C;
        }
        td{
          text-align: center;
          font-weight: 500;
          letter-spacing: 0.02em;
          padding: .7rem;
          font-feature-settings: 'pnum' on, 'lnum' on;
          width: 33.33333333%;
          @include media-breakpoint-down(xx) {
            font-size: 1.4rem;
          }
          &:not(:last-child){
            border-right: 2px solid #7C7C7C;
          }
        }
      }
    }
  }
  &.two-thirds {
    table {
      width: 66.66666%;
      @include media-breakpoint-down(xx) {
        width: 100%;
      }
    }
  }
}