

// Colors

$accent:         #EC6B41;
$dark :          #0A0A0A;
$dark-gray:      #7C7C7C;
$gray:           #D2D2D2;
$light-gray:     #EEEEEE;

// Links
//
// Style anchor elements.

$link-color:                             $dark;
$link-decoration:                        none;
$link-hover-color:                       $accent;
$link-hover-decoration:                  none;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
// $font-family-base:  'Raleway' , 'sans-serif';

// stylelint-enable value-keyword-case

$font-size-base:              1.6rem; // Assumes the browser default, typically `16px`

$font-weight-base:            $font-weight-normal !default;

$h1-font-size:                5.2rem;
$h2-font-size:                3.6rem;
$h3-font-size:                2.4rem;
$h4-font-size:                2.2rem;
$h5-font-size:                1.8rem;
$h6-font-size:                1.4rem;

$headings-margin-bottom:      $spacer / 2 !default;
$headings-font-weight:        900;
$headings-line-height:        1.15;
$headings-color:              null !default;



// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12;
$grid-gutter-width:           2rem;
$grid-row-columns:            1;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xx: 0,
  xs: 500px,
  sm: 720px,
  md: 992px,
  lg: 1200px,
  xl: 1400px
);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 690px,
  md: 960px,
  lg: 1180px,
  xl: 1200px
);

