*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #0A0A0A;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #EC6B41;
    text-decoration: none; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 900;
  line-height: 1.15; }

h1, .h1 {
  font-size: 5.2rem; }

h2, .h2 {
  font-size: 3.6rem; }

h3, .h3 {
  font-size: 2.4rem; }

h4, .h4 {
  font-size: 2.2rem; }

h5, .h5 {
  font-size: 1.8rem; }

h6, .h6 {
  font-size: 1.4rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: max(0.25rem, 0);
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: max(0.2rem, 0); }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 720px) {
  .container, .container-xs, .container-sm {
    max-width: 690px; } }

@media (min-width: 992px) {
  .container, .container-xs, .container-sm, .container-md {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-xs, .container-sm, .container-md, .container-lg {
    max-width: 1180px; } }

@media (min-width: 1400px) {
  .container, .container-xs, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1200px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs,
.col-xs-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 500px) {
  .col-xs {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xs-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .col-xs-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xs-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xs-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xs-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xs-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xs-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xs-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xs-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xs-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xs-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xs-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xs-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xs-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xs-first {
    order: -1; }
  .order-xs-last {
    order: 13; }
  .order-xs-0 {
    order: 0; }
  .order-xs-1 {
    order: 1; }
  .order-xs-2 {
    order: 2; }
  .order-xs-3 {
    order: 3; }
  .order-xs-4 {
    order: 4; }
  .order-xs-5 {
    order: 5; }
  .order-xs-6 {
    order: 6; }
  .order-xs-7 {
    order: 7; }
  .order-xs-8 {
    order: 8; }
  .order-xs-9 {
    order: 9; }
  .order-xs-10 {
    order: 10; }
  .order-xs-11 {
    order: 11; }
  .order-xs-12 {
    order: 12; }
  .offset-xs-0 {
    margin-left: 0; }
  .offset-xs-1 {
    margin-left: 8.33333%; }
  .offset-xs-2 {
    margin-left: 16.66667%; }
  .offset-xs-3 {
    margin-left: 25%; }
  .offset-xs-4 {
    margin-left: 33.33333%; }
  .offset-xs-5 {
    margin-left: 41.66667%; }
  .offset-xs-6 {
    margin-left: 50%; }
  .offset-xs-7 {
    margin-left: 58.33333%; }
  .offset-xs-8 {
    margin-left: 66.66667%; }
  .offset-xs-9 {
    margin-left: 75%; }
  .offset-xs-10 {
    margin-left: 83.33333%; }
  .offset-xs-11 {
    margin-left: 91.66667%; } }

@media (min-width: 720px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1400px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 499.98px) {
  .table-responsive-xs {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xs > .table-bordered {
      border: 0; } }

@media (max-width: 719.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1399.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: max(0.25rem, 0);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: max(0.2rem, 0); }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: max(0.3rem, 0); }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: max(0.25rem, 0); }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: max(0.25rem, 0); }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 720px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: max(0.25rem, 0);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #0A0A0A;
  text-decoration: none; }
  .btn-link:hover {
    color: #EC6B41;
    text-decoration: none; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: max(0.3rem, 0); }

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: max(0.2rem, 0); }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: max(0.25rem, 0); }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 500px) {
  .dropdown-menu-xs-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xs-right {
    right: 0;
    left: auto; } }

@media (min-width: 720px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1400px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: max(0.3rem, 0);
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 720px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 1200px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1400px) {
  .modal-xl {
    max-width: 1140px; } }

.modal .close {
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 9999; }
  .modal .close .icon-close {
    color: white;
    font-size: 3rem; }
    @media (max-width: 499.98px) {
      .modal .close .icon-close {
        font-size: 2rem; } }

.modal-content {
  background-color: #0A0A0A; }

.modal-dialog {
  max-width: 82rem; }

.modal-backdrop {
  background: rgba(124, 124, 124, 0.9); }
  .modal-backdrop.show {
    opacity: 1; }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: max(0.25rem, 0); }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: max(0.3rem, 0); }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1.6rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 500px) {
  .d-xs-none {
    display: none !important; }
  .d-xs-inline {
    display: inline !important; }
  .d-xs-inline-block {
    display: inline-block !important; }
  .d-xs-block {
    display: block !important; }
  .d-xs-table {
    display: table !important; }
  .d-xs-table-row {
    display: table-row !important; }
  .d-xs-table-cell {
    display: table-cell !important; }
  .d-xs-flex {
    display: flex !important; }
  .d-xs-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 720px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1400px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 500px) {
  .flex-xs-row {
    flex-direction: row !important; }
  .flex-xs-column {
    flex-direction: column !important; }
  .flex-xs-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xs-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xs-wrap {
    flex-wrap: wrap !important; }
  .flex-xs-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xs-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xs-fill {
    flex: 1 1 auto !important; }
  .flex-xs-grow-0 {
    flex-grow: 0 !important; }
  .flex-xs-grow-1 {
    flex-grow: 1 !important; }
  .flex-xs-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xs-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xs-start {
    justify-content: flex-start !important; }
  .justify-content-xs-end {
    justify-content: flex-end !important; }
  .justify-content-xs-center {
    justify-content: center !important; }
  .justify-content-xs-between {
    justify-content: space-between !important; }
  .justify-content-xs-around {
    justify-content: space-around !important; }
  .align-items-xs-start {
    align-items: flex-start !important; }
  .align-items-xs-end {
    align-items: flex-end !important; }
  .align-items-xs-center {
    align-items: center !important; }
  .align-items-xs-baseline {
    align-items: baseline !important; }
  .align-items-xs-stretch {
    align-items: stretch !important; }
  .align-content-xs-start {
    align-content: flex-start !important; }
  .align-content-xs-end {
    align-content: flex-end !important; }
  .align-content-xs-center {
    align-content: center !important; }
  .align-content-xs-between {
    align-content: space-between !important; }
  .align-content-xs-around {
    align-content: space-around !important; }
  .align-content-xs-stretch {
    align-content: stretch !important; }
  .align-self-xs-auto {
    align-self: auto !important; }
  .align-self-xs-start {
    align-self: flex-start !important; }
  .align-self-xs-end {
    align-self: flex-end !important; }
  .align-self-xs-center {
    align-self: center !important; }
  .align-self-xs-baseline {
    align-self: baseline !important; }
  .align-self-xs-stretch {
    align-self: stretch !important; } }

@media (min-width: 720px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1400px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 500px) {
  .float-xs-left {
    float: left !important; }
  .float-xs-right {
    float: right !important; }
  .float-xs-none {
    float: none !important; } }

@media (min-width: 720px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1400px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 500px) {
  .m-xs-0 {
    margin: 0 !important; }
  .mt-xs-0,
  .my-xs-0 {
    margin-top: 0 !important; }
  .mr-xs-0,
  .mx-xs-0 {
    margin-right: 0 !important; }
  .mb-xs-0,
  .my-xs-0 {
    margin-bottom: 0 !important; }
  .ml-xs-0,
  .mx-xs-0 {
    margin-left: 0 !important; }
  .m-xs-1 {
    margin: 0.25rem !important; }
  .mt-xs-1,
  .my-xs-1 {
    margin-top: 0.25rem !important; }
  .mr-xs-1,
  .mx-xs-1 {
    margin-right: 0.25rem !important; }
  .mb-xs-1,
  .my-xs-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xs-1,
  .mx-xs-1 {
    margin-left: 0.25rem !important; }
  .m-xs-2 {
    margin: 0.5rem !important; }
  .mt-xs-2,
  .my-xs-2 {
    margin-top: 0.5rem !important; }
  .mr-xs-2,
  .mx-xs-2 {
    margin-right: 0.5rem !important; }
  .mb-xs-2,
  .my-xs-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xs-2,
  .mx-xs-2 {
    margin-left: 0.5rem !important; }
  .m-xs-3 {
    margin: 1rem !important; }
  .mt-xs-3,
  .my-xs-3 {
    margin-top: 1rem !important; }
  .mr-xs-3,
  .mx-xs-3 {
    margin-right: 1rem !important; }
  .mb-xs-3,
  .my-xs-3 {
    margin-bottom: 1rem !important; }
  .ml-xs-3,
  .mx-xs-3 {
    margin-left: 1rem !important; }
  .m-xs-4 {
    margin: 1.5rem !important; }
  .mt-xs-4,
  .my-xs-4 {
    margin-top: 1.5rem !important; }
  .mr-xs-4,
  .mx-xs-4 {
    margin-right: 1.5rem !important; }
  .mb-xs-4,
  .my-xs-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xs-4,
  .mx-xs-4 {
    margin-left: 1.5rem !important; }
  .m-xs-5 {
    margin: 3rem !important; }
  .mt-xs-5,
  .my-xs-5 {
    margin-top: 3rem !important; }
  .mr-xs-5,
  .mx-xs-5 {
    margin-right: 3rem !important; }
  .mb-xs-5,
  .my-xs-5 {
    margin-bottom: 3rem !important; }
  .ml-xs-5,
  .mx-xs-5 {
    margin-left: 3rem !important; }
  .p-xs-0 {
    padding: 0 !important; }
  .pt-xs-0,
  .py-xs-0 {
    padding-top: 0 !important; }
  .pr-xs-0,
  .px-xs-0 {
    padding-right: 0 !important; }
  .pb-xs-0,
  .py-xs-0 {
    padding-bottom: 0 !important; }
  .pl-xs-0,
  .px-xs-0 {
    padding-left: 0 !important; }
  .p-xs-1 {
    padding: 0.25rem !important; }
  .pt-xs-1,
  .py-xs-1 {
    padding-top: 0.25rem !important; }
  .pr-xs-1,
  .px-xs-1 {
    padding-right: 0.25rem !important; }
  .pb-xs-1,
  .py-xs-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xs-1,
  .px-xs-1 {
    padding-left: 0.25rem !important; }
  .p-xs-2 {
    padding: 0.5rem !important; }
  .pt-xs-2,
  .py-xs-2 {
    padding-top: 0.5rem !important; }
  .pr-xs-2,
  .px-xs-2 {
    padding-right: 0.5rem !important; }
  .pb-xs-2,
  .py-xs-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xs-2,
  .px-xs-2 {
    padding-left: 0.5rem !important; }
  .p-xs-3 {
    padding: 1rem !important; }
  .pt-xs-3,
  .py-xs-3 {
    padding-top: 1rem !important; }
  .pr-xs-3,
  .px-xs-3 {
    padding-right: 1rem !important; }
  .pb-xs-3,
  .py-xs-3 {
    padding-bottom: 1rem !important; }
  .pl-xs-3,
  .px-xs-3 {
    padding-left: 1rem !important; }
  .p-xs-4 {
    padding: 1.5rem !important; }
  .pt-xs-4,
  .py-xs-4 {
    padding-top: 1.5rem !important; }
  .pr-xs-4,
  .px-xs-4 {
    padding-right: 1.5rem !important; }
  .pb-xs-4,
  .py-xs-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xs-4,
  .px-xs-4 {
    padding-left: 1.5rem !important; }
  .p-xs-5 {
    padding: 3rem !important; }
  .pt-xs-5,
  .py-xs-5 {
    padding-top: 3rem !important; }
  .pr-xs-5,
  .px-xs-5 {
    padding-right: 3rem !important; }
  .pb-xs-5,
  .py-xs-5 {
    padding-bottom: 3rem !important; }
  .pl-xs-5,
  .px-xs-5 {
    padding-left: 3rem !important; }
  .m-xs-n1 {
    margin: -0.25rem !important; }
  .mt-xs-n1,
  .my-xs-n1 {
    margin-top: -0.25rem !important; }
  .mr-xs-n1,
  .mx-xs-n1 {
    margin-right: -0.25rem !important; }
  .mb-xs-n1,
  .my-xs-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xs-n1,
  .mx-xs-n1 {
    margin-left: -0.25rem !important; }
  .m-xs-n2 {
    margin: -0.5rem !important; }
  .mt-xs-n2,
  .my-xs-n2 {
    margin-top: -0.5rem !important; }
  .mr-xs-n2,
  .mx-xs-n2 {
    margin-right: -0.5rem !important; }
  .mb-xs-n2,
  .my-xs-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xs-n2,
  .mx-xs-n2 {
    margin-left: -0.5rem !important; }
  .m-xs-n3 {
    margin: -1rem !important; }
  .mt-xs-n3,
  .my-xs-n3 {
    margin-top: -1rem !important; }
  .mr-xs-n3,
  .mx-xs-n3 {
    margin-right: -1rem !important; }
  .mb-xs-n3,
  .my-xs-n3 {
    margin-bottom: -1rem !important; }
  .ml-xs-n3,
  .mx-xs-n3 {
    margin-left: -1rem !important; }
  .m-xs-n4 {
    margin: -1.5rem !important; }
  .mt-xs-n4,
  .my-xs-n4 {
    margin-top: -1.5rem !important; }
  .mr-xs-n4,
  .mx-xs-n4 {
    margin-right: -1.5rem !important; }
  .mb-xs-n4,
  .my-xs-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xs-n4,
  .mx-xs-n4 {
    margin-left: -1.5rem !important; }
  .m-xs-n5 {
    margin: -3rem !important; }
  .mt-xs-n5,
  .my-xs-n5 {
    margin-top: -3rem !important; }
  .mr-xs-n5,
  .mx-xs-n5 {
    margin-right: -3rem !important; }
  .mb-xs-n5,
  .my-xs-n5 {
    margin-bottom: -3rem !important; }
  .ml-xs-n5,
  .mx-xs-n5 {
    margin-left: -3rem !important; }
  .m-xs-auto {
    margin: auto !important; }
  .mt-xs-auto,
  .my-xs-auto {
    margin-top: auto !important; }
  .mr-xs-auto,
  .mx-xs-auto {
    margin-right: auto !important; }
  .mb-xs-auto,
  .my-xs-auto {
    margin-bottom: auto !important; }
  .ml-xs-auto,
  .mx-xs-auto {
    margin-left: auto !important; } }

@media (min-width: 720px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1400px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 500px) {
  .text-xs-left {
    text-align: left !important; }
  .text-xs-right {
    text-align: right !important; }
  .text-xs-center {
    text-align: center !important; } }

@media (min-width: 720px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1400px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.dropdown-toggle {
  line-height: normal;
  display: flex;
  align-items: center;
  transition: all .2s ease; }
  .dropdown-toggle:after {
    display: none; }

.dropdown .icon-arrow {
  font-size: .75rem;
  margin-left: .3rem; }

.dropdown:hover .dropdown-toggle {
  color: #EC6B41; }

.dropdown.show .icon-arrow {
  transform: rotateX(180deg); }

.dropdown-menu {
  padding: 0.5rem 0;
  border: 0.1rem solid #D2D2D2;
  border-radius: .2rem;
  min-width: 6rem; }
  .dropdown-menu.show {
    top: 1rem !important;
    left: auto !important;
    right: 0; }

.dropdown-item {
  font-size: 1.4rem;
  color: #0A0A0A;
  padding: 0.5rem 1rem;
  transition: all .2s ease; }
  .dropdown-item:hover {
    color: #EC6B41;
    background-color: inherit; }

@font-face {
  font-family: lg;
  src: url(../fonts/lg.ttf?22t19m) format("truetype"), url(../fonts/lg.woff?22t19m) format("woff"), url(../fonts/lg.svg?22t19m#lg) format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: block; }

.lg-icon {
  font-family: lg !important;
  speak: never;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.lg-actions .lg-next, .lg-actions .lg-prev {
  border-radius: 2px;
  color: #999;
  cursor: pointer;
  display: block;
  font-size: 22px;
  margin-top: -10px;
  padding: 8px 10px 9px;
  position: absolute;
  top: 50%;
  z-index: 1080;
  outline: 0;
  border: none;
  background-color: transparent; }

.lg-actions .lg-next.disabled, .lg-actions .lg-prev.disabled {
  pointer-events: none;
  opacity: .5; }

.lg-actions .lg-next:hover, .lg-actions .lg-prev:hover {
  color: #FFF; }

.lg-actions .lg-next {
  right: 20px; }

.lg-actions .lg-next:before {
  content: "\e095"; }

.lg-actions .lg-prev {
  left: 20px; }

.lg-actions .lg-prev:after {
  content: "\e094"; }

@-webkit-keyframes lg-right-end {
  0%, 100% {
    left: 0; }
  50% {
    left: -30px; } }

@-moz-keyframes lg-right-end {
  0%, 100% {
    left: 0; }
  50% {
    left: -30px; } }

@-ms-keyframes lg-right-end {
  0%, 100% {
    left: 0; }
  50% {
    left: -30px; } }

@keyframes lg-right-end {
  0%, 100% {
    left: 0; }
  50% {
    left: -30px; } }

@-webkit-keyframes lg-left-end {
  0%, 100% {
    left: 0; }
  50% {
    left: 30px; } }

@-moz-keyframes lg-left-end {
  0%, 100% {
    left: 0; }
  50% {
    left: 30px; } }

@-ms-keyframes lg-left-end {
  0%, 100% {
    left: 0; }
  50% {
    left: 30px; } }

@keyframes lg-left-end {
  0%, 100% {
    left: 0; }
  50% {
    left: 30px; } }

.lg-outer.lg-right-end .lg-object {
  -webkit-animation: lg-right-end .3s;
  -o-animation: lg-right-end .3s;
  animation: lg-right-end .3s;
  position: relative; }

.lg-outer.lg-left-end .lg-object {
  -webkit-animation: lg-left-end .3s;
  -o-animation: lg-left-end .3s;
  animation: lg-left-end .3s;
  position: relative; }

.lg-toolbar {
  z-index: 1082;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.45); }

.lg-toolbar .lg-icon {
  color: #999;
  cursor: pointer;
  float: right;
  font-size: 24px;
  height: 47px;
  line-height: 27px;
  padding: 10px 0;
  text-align: center;
  width: 50px;
  text-decoration: none !important;
  outline: 0;
  background: 0 0;
  border: none;
  box-shadow: none;
  -webkit-transition: color .2s linear;
  -o-transition: color .2s linear;
  transition: color .2s linear; }

.lg-toolbar .lg-icon:hover {
  color: #FFF; }

.lg-toolbar .lg-close:after {
  content: "\e070"; }

.lg-toolbar .lg-download:after {
  content: "\e0f2"; }

.lg-sub-html {
  background-color: rgba(0, 0, 0, 0.45);
  bottom: 0;
  color: #EEE;
  font-size: 16px;
  left: 0;
  padding: 10px 40px;
  position: fixed;
  right: 0;
  text-align: center;
  z-index: 1080; }

.lg-sub-html h4 {
  margin: 0;
  font-size: 13px;
  font-weight: 700; }

.lg-sub-html p {
  font-size: 12px;
  margin: 5px 0 0; }

#lg-counter {
  color: #999;
  display: inline-block;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 12px;
  vertical-align: middle; }

.lg-next, .lg-prev, .lg-toolbar {
  opacity: 1;
  -webkit-transition: -webkit-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -moz-transition: -moz-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -o-transition: -o-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  transition: transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear; }

.lg-hide-items .lg-prev {
  opacity: 0;
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0); }

.lg-hide-items .lg-next {
  opacity: 0;
  -webkit-transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0); }

.lg-hide-items .lg-toolbar {
  opacity: 0;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0); }

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-object {
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
  opacity: 0;
  -webkit-transition: -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -moz-transition: -moz-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -o-transition: -o-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%; }

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item.lg-complete .lg-object {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1; }

.lg-outer .lg-thumb-outer {
  background-color: #0D0A0A;
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 1080;
  max-height: 350px;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s; }

.lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab; }

.lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing; }

.lg-outer .lg-thumb-outer.lg-dragging .lg-thumb {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important; }

.lg-outer.lg-thumb-open .lg-thumb-outer {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.lg-outer .lg-thumb {
  padding: 10px 0;
  height: 100%;
  margin-bottom: -5px; }

.lg-outer .lg-thumb-item {
  cursor: pointer;
  float: left;
  overflow: hidden;
  height: 100%;
  border: 2px solid #FFF;
  border-radius: 4px;
  margin-bottom: 5px; }

@media (min-width: 1025px) {
  .lg-outer .lg-thumb-item {
    -webkit-transition: border-color .25s ease;
    -o-transition: border-color .25s ease;
    transition: border-color .25s ease; } }

.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
  border-color: #a90707; }

.lg-outer .lg-thumb-item img {
  width: 100%;
  height: 100%;
  object-fit: cover; }

.lg-outer.lg-has-thumb .lg-item {
  padding-bottom: 120px; }

.lg-outer.lg-can-toggle .lg-item {
  padding-bottom: 0; }

.lg-outer.lg-pull-caption-up .lg-sub-html {
  -webkit-transition: bottom .25s ease;
  -o-transition: bottom .25s ease;
  transition: bottom .25s ease; }

.lg-outer.lg-pull-caption-up.lg-thumb-open .lg-sub-html {
  bottom: 100px; }

.lg-outer .lg-toggle-thumb {
  background-color: #0D0A0A;
  border-radius: 2px 2px 0 0;
  color: #999;
  cursor: pointer;
  font-size: 24px;
  height: 39px;
  line-height: 27px;
  padding: 5px 0;
  position: absolute;
  right: 20px;
  text-align: center;
  top: -39px;
  width: 50px;
  outline: 0;
  border: none; }

.lg-outer .lg-toggle-thumb:after {
  content: "\e1ff"; }

.lg-outer .lg-toggle-thumb:hover {
  color: #FFF; }

.lg-outer .lg-video-cont {
  display: inline-block;
  vertical-align: middle;
  max-width: 1140px;
  max-height: 100%;
  width: 100%;
  padding: 0 5px; }

.lg-outer .lg-video {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative; }

.lg-outer .lg-video .lg-object {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important; }

.lg-outer .lg-video .lg-video-play {
  width: 84px;
  height: 59px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -42px;
  margin-top: -30px;
  z-index: 1080;
  cursor: pointer; }

.lg-outer .lg-has-vimeo .lg-video-play {
  background: url(../img/vimeo-play.png) no-repeat; }

.lg-outer .lg-has-vimeo:hover .lg-video-play {
  background: url(../img/vimeo-play.png) 0 -58px no-repeat; }

.lg-outer .lg-has-html5 .lg-video-play {
  background: url(../img/video-play.png) no-repeat;
  height: 64px;
  margin-left: -32px;
  margin-top: -32px;
  width: 64px;
  opacity: .8; }

.lg-outer .lg-has-html5:hover .lg-video-play {
  opacity: 1; }

.lg-outer .lg-has-youtube .lg-video-play {
  background: url(../img/youtube-play.png) no-repeat; }

.lg-outer .lg-has-youtube:hover .lg-video-play {
  background: url(../img/youtube-play.png) 0 -60px no-repeat; }

.lg-outer .lg-video-object {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0; }

.lg-outer .lg-has-video .lg-video-object {
  visibility: hidden; }

.lg-outer .lg-has-video.lg-video-playing .lg-object, .lg-outer .lg-has-video.lg-video-playing .lg-video-play {
  display: none; }

.lg-outer .lg-has-video.lg-video-playing .lg-video-object {
  visibility: visible; }

.lg-progress-bar {
  background-color: #333;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1083;
  opacity: 0;
  -webkit-transition: opacity 80ms ease 0s;
  -moz-transition: opacity 80ms ease 0s;
  -o-transition: opacity 80ms ease 0s;
  transition: opacity 80ms ease 0s; }

.lg-progress-bar .lg-progress {
  background-color: #a90707;
  height: 5px;
  width: 0; }

.lg-progress-bar.lg-start .lg-progress {
  width: 100%; }

.lg-show-autoplay .lg-progress-bar {
  opacity: 1; }

.lg-autoplay-button:after {
  content: "\e01d"; }

.lg-show-autoplay .lg-autoplay-button:after {
  content: "\e01a"; }

.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image, .lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition-duration: 0s;
  transition-duration: 0s; }

.lg-outer.lg-use-transition-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s; }

.lg-outer.lg-use-left-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s; }

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

#lg-zoom-in:after {
  content: "\e311"; }

#lg-actual-size {
  font-size: 20px; }

#lg-actual-size:after {
  content: "\e033"; }

#lg-zoom-out {
  opacity: .5;
  pointer-events: none; }

#lg-zoom-out:after {
  content: "\e312"; }

.lg-zoomed #lg-zoom-out {
  opacity: 1;
  pointer-events: auto; }

.lg-outer .lg-pager-outer {
  bottom: 60px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 1080;
  height: 10px; }

.lg-outer .lg-pager-outer.lg-pager-hover .lg-pager-cont {
  overflow: visible; }

.lg-outer .lg-pager-cont {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  margin: 0 5px; }

.lg-outer .lg-pager-cont:hover .lg-pager-thumb-cont {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.lg-outer .lg-pager-cont.lg-pager-active .lg-pager {
  box-shadow: 0 0 0 2px #fff inset; }

.lg-outer .lg-pager-thumb-cont {
  background-color: #fff;
  color: #FFF;
  bottom: 100%;
  height: 83px;
  left: 0;
  margin-bottom: 20px;
  margin-left: -60px;
  opacity: 0;
  padding: 5px;
  position: absolute;
  width: 120px;
  border-radius: 3px;
  -webkit-transition: opacity .15s ease 0s,-webkit-transform .15s ease 0s;
  -moz-transition: opacity .15s ease 0s,-moz-transform .15s ease 0s;
  -o-transition: opacity .15s ease 0s,-o-transform .15s ease 0s;
  transition: opacity .15s ease 0s,transform .15s ease 0s;
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0); }

.lg-outer .lg-pager-thumb-cont img {
  width: 100%;
  height: 100%; }

.lg-outer .lg-pager {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.7) inset;
  display: block;
  height: 12px;
  -webkit-transition: box-shadow .3s ease 0s;
  -o-transition: box-shadow .3s ease 0s;
  transition: box-shadow .3s ease 0s;
  width: 12px; }

.lg-outer .lg-pager:focus, .lg-outer .lg-pager:hover {
  box-shadow: 0 0 0 8px #fff inset; }

.lg-outer .lg-caret {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px dashed;
  bottom: -10px;
  display: inline-block;
  height: 0;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  vertical-align: middle;
  width: 0; }

.lg-fullscreen:after {
  content: "\e20c"; }

.lg-fullscreen-on .lg-fullscreen:after {
  content: "\e20d"; }

.lg-outer #lg-dropdown-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  bottom: 0;
  cursor: default;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1081;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: visibility 0s linear .18s,opacity .18s linear 0s;
  -o-transition: visibility 0s linear .18s,opacity .18s linear 0s;
  transition: visibility 0s linear .18s,opacity .18s linear 0s; }

.lg-outer.lg-dropdown-active #lg-dropdown-overlay, .lg-outer.lg-dropdown-active .lg-dropdown {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
  visibility: visible; }

.lg-outer.lg-dropdown-active #lg-share {
  color: #FFF; }

.lg-outer .lg-dropdown {
  background-color: #fff;
  border-radius: 2px;
  font-size: 14px;
  list-style-type: none;
  margin: 0;
  padding: 10px 0;
  position: absolute;
  right: 0;
  text-align: left;
  top: 50px;
  opacity: 0;
  visibility: hidden;
  -moz-transform: translate3d(0, 5px, 0);
  -o-transform: translate3d(0, 5px, 0);
  -ms-transform: translate3d(0, 5px, 0);
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
  -webkit-transition: -webkit-transform .18s linear 0s,visibility 0s linear .5s,opacity .18s linear 0s;
  -moz-transition: -moz-transform .18s linear 0s,visibility 0s linear .5s,opacity .18s linear 0s;
  -o-transition: -o-transform .18s linear 0s,visibility 0s linear .5s,opacity .18s linear 0s;
  transition: transform .18s linear 0s,visibility 0s linear .5s,opacity .18s linear 0s; }

.lg-outer .lg-dropdown:after {
  content: "";
  display: block;
  height: 0;
  width: 0;
  position: absolute;
  border: 8px solid transparent;
  border-bottom-color: #FFF;
  right: 16px;
  top: -16px; }

.lg-outer .lg-dropdown > li:last-child {
  margin-bottom: 0; }

.lg-outer .lg-dropdown > li:hover .lg-icon, .lg-outer .lg-dropdown > li:hover a {
  color: #333; }

.lg-outer .lg-dropdown a {
  color: #333;
  display: block;
  white-space: pre;
  padding: 4px 12px;
  font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 12px; }

.lg-outer .lg-dropdown a:hover {
  background-color: rgba(0, 0, 0, 0.07); }

.lg-outer .lg-dropdown .lg-dropdown-text {
  display: inline-block;
  line-height: 1;
  margin-top: -3px;
  vertical-align: middle; }

.lg-outer .lg-dropdown .lg-icon {
  color: #333;
  display: inline-block;
  float: none;
  font-size: 20px;
  height: auto;
  line-height: 1;
  margin-right: 8px;
  padding: 0;
  vertical-align: middle;
  width: auto; }

.lg-outer, .lg-outer .lg, .lg-outer .lg-inner {
  height: 100%;
  width: 100%; }

.lg-outer #lg-share {
  position: relative; }

.lg-outer #lg-share:after {
  content: "\e80d"; }

.lg-outer #lg-share-facebook .lg-icon {
  color: #3b5998; }

.lg-outer #lg-share-facebook .lg-icon:after {
  content: "\e904"; }

.lg-outer #lg-share-twitter .lg-icon {
  color: #00aced; }

.lg-outer #lg-share-twitter .lg-icon:after {
  content: "\e907"; }

.lg-outer #lg-share-googleplus .lg-icon {
  color: #dd4b39; }

.lg-outer #lg-share-googleplus .lg-icon:after {
  content: "\e905"; }

.lg-outer #lg-share-pinterest .lg-icon {
  color: #cb2027; }

.lg-outer #lg-share-pinterest .lg-icon:after {
  content: "\e906"; }

.lg-outer .lg-img-rotate {
  position: absolute;
  padding: 0 5px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.32, 0, 0.67, 0) 0s;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0.32, 0, 0.67, 0) 0s;
  -o-transition: -o-transform 0.3s cubic-bezier(0.32, 0, 0.67, 0) 0s;
  transition: transform 0.3s cubic-bezier(0.32, 0, 0.67, 0) 0s; }

.lg-rotate-left:after {
  content: "\e900"; }

.lg-rotate-right:after {
  content: "\e901"; }

.lg-icon.lg-flip-hor, .lg-icon.lg-flip-ver {
  font-size: 26px; }

.lg-flip-hor:after {
  content: "\e902"; }

.lg-flip-ver:after {
  content: "\e903"; }

.lg-group:after, .lg-group:before {
  display: table;
  content: "";
  line-height: 0; }

.lg-group:after {
  clear: both; }

.lg-outer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  opacity: 0;
  outline: 0;
  -webkit-transition: opacity .15s ease 0s;
  -o-transition: opacity .15s ease 0s;
  transition: opacity .15s ease 0s; }

.lg-outer * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.lg-outer.lg-visible {
  opacity: 1; }

.lg-outer.lg-css3 .lg-item.lg-current, .lg-outer.lg-css3 .lg-item.lg-next-slide, .lg-outer.lg-css3 .lg-item.lg-prev-slide {
  -webkit-transition-duration: inherit !important;
  transition-duration: inherit !important;
  -webkit-transition-timing-function: inherit !important;
  transition-timing-function: inherit !important; }

.lg-outer.lg-css3.lg-dragging .lg-item.lg-current, .lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
  opacity: 1; }

.lg-outer.lg-grab img.lg-object {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab; }

.lg-outer.lg-grabbing img.lg-object {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing; }

.lg-outer .lg {
  position: relative;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  max-height: 100%; }

.lg-outer .lg-inner {
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap; }

.lg-outer .lg-item {
  background: url(../img/loading.gif) center center no-repeat;
  display: none !important; }

.lg-outer.lg-css .lg-current, .lg-outer.lg-css3 .lg-current, .lg-outer.lg-css3 .lg-next-slide, .lg-outer.lg-css3 .lg-prev-slide {
  display: inline-block !important; }

.lg-outer .lg-img-wrap, .lg-outer .lg-item {
  display: inline-block;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%; }

.lg-outer .lg-img-wrap:before, .lg-outer .lg-item:before {
  content: "";
  display: inline-block;
  height: 50%;
  width: 1px;
  margin-right: -1px; }

.lg-outer .lg-img-wrap {
  position: absolute;
  padding: 0 5px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0; }

.lg-outer .lg-item.lg-complete {
  background-image: none; }

.lg-outer .lg-item.lg-current {
  z-index: 1060; }

.lg-outer .lg-image {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
  width: auto !important;
  height: auto !important; }

.lg-outer.lg-show-after-load .lg-item .lg-object, .lg-outer.lg-show-after-load .lg-item .lg-video-play {
  opacity: 0;
  -webkit-transition: opacity .15s ease 0s;
  -o-transition: opacity .15s ease 0s;
  transition: opacity .15s ease 0s; }

.lg-outer.lg-show-after-load .lg-item.lg-complete .lg-object, .lg-outer.lg-show-after-load .lg-item.lg-complete .lg-video-play {
  opacity: 1; }

.lg-outer .lg-empty-html, .lg-outer.lg-hide-download #lg-download {
  display: none; }

.lg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0;
  -webkit-transition: opacity .15s ease 0s;
  -o-transition: opacity .15s ease 0s;
  transition: opacity .15s ease 0s; }

.lg-backdrop.in {
  opacity: 1; }

.lg-css3.lg-no-trans .lg-current, .lg-css3.lg-no-trans .lg-next-slide, .lg-css3.lg-no-trans .lg-prev-slide {
  -webkit-transition: none 0s ease 0s !important;
  -moz-transition: none 0s ease 0s !important;
  -o-transition: none 0s ease 0s !important;
  transition: none 0s ease 0s !important; }

.lg-css3.lg-use-css3 .lg-item, .lg-css3.lg-use-left .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

.lg-css3.lg-fade .lg-item {
  opacity: 0; }

.lg-css3.lg-fade .lg-item.lg-current {
  opacity: 1; }

.lg-css3.lg-fade .lg-item.lg-current, .lg-css3.lg-fade .lg-item.lg-next-slide, .lg-css3.lg-fade .lg-item.lg-prev-slide {
  -webkit-transition: opacity .1s ease 0s;
  -moz-transition: opacity .1s ease 0s;
  -o-transition: opacity .1s ease 0s;
  transition: opacity .1s ease 0s; }

.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0; }

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0); }

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0); }

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1; }

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide.lg-use-left .lg-item {
  opacity: 0;
  position: absolute;
  left: 0; }

.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
  left: -100%; }

.lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide {
  left: 100%; }

.lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
  left: 0;
  opacity: 1; }

.lg-css3.lg-slide.lg-use-left .lg-item.lg-current, .lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
  -webkit-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-zoom-in .lg-item {
  opacity: 0; }

.lg-css3.lg-zoom-in .lg-item.lg-next-slide, .lg-css3.lg-zoom-in .lg-item.lg-prev-slide {
  -webkit-transform: scale3d(1.3, 1.3, 1.3);
  transform: scale3d(1.3, 1.3, 1.3); }

.lg-css3.lg-zoom-in .lg-item.lg-current {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1; }

.lg-css3.lg-zoom-in .lg-item.lg-current, .lg-css3.lg-zoom-in .lg-item.lg-next-slide, .lg-css3.lg-zoom-in .lg-item.lg-prev-slide {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-zoom-in-big .lg-item {
  opacity: 0; }

.lg-css3.lg-zoom-in-big .lg-item.lg-next-slide, .lg-css3.lg-zoom-in-big .lg-item.lg-prev-slide {
  -webkit-transform: scale3d(2, 2, 2);
  transform: scale3d(2, 2, 2); }

.lg-css3.lg-zoom-in-big .lg-item.lg-current {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1; }

.lg-css3.lg-zoom-in-big .lg-item.lg-current, .lg-css3.lg-zoom-in-big .lg-item.lg-next-slide, .lg-css3.lg-zoom-in-big .lg-item.lg-prev-slide {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-zoom-out .lg-item {
  opacity: 0; }

.lg-css3.lg-zoom-out .lg-item.lg-next-slide, .lg-css3.lg-zoom-out .lg-item.lg-prev-slide {
  -webkit-transform: scale3d(0.7, 0.7, 0.7);
  transform: scale3d(0.7, 0.7, 0.7); }

.lg-css3.lg-zoom-out .lg-item.lg-current {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1; }

.lg-css3.lg-zoom-out .lg-item.lg-current, .lg-css3.lg-zoom-out .lg-item.lg-next-slide, .lg-css3.lg-zoom-out .lg-item.lg-prev-slide {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-zoom-out-big .lg-item {
  opacity: 0; }

.lg-css3.lg-zoom-out-big .lg-item.lg-next-slide, .lg-css3.lg-zoom-out-big .lg-item.lg-prev-slide {
  -webkit-transform: scale3d(0, 0, 0);
  transform: scale3d(0, 0, 0); }

.lg-css3.lg-zoom-out-big .lg-item.lg-current {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1; }

.lg-css3.lg-zoom-out-big .lg-item.lg-current, .lg-css3.lg-zoom-out-big .lg-item.lg-next-slide, .lg-css3.lg-zoom-out-big .lg-item.lg-prev-slide {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-zoom-out-in .lg-item {
  opacity: 0; }

.lg-css3.lg-zoom-out-in .lg-item.lg-prev-slide {
  -webkit-transform: scale3d(0, 0, 0);
  transform: scale3d(0, 0, 0); }

.lg-css3.lg-zoom-out-in .lg-item.lg-next-slide {
  -webkit-transform: scale3d(2, 2, 2);
  transform: scale3d(2, 2, 2); }

.lg-css3.lg-zoom-out-in .lg-item.lg-current {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1; }

.lg-css3.lg-zoom-out-in .lg-item.lg-current, .lg-css3.lg-zoom-out-in .lg-item.lg-next-slide, .lg-css3.lg-zoom-out-in .lg-item.lg-prev-slide {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-zoom-in-out .lg-item {
  opacity: 0; }

.lg-css3.lg-zoom-in-out .lg-item.lg-prev-slide {
  -webkit-transform: scale3d(2, 2, 2);
  transform: scale3d(2, 2, 2); }

.lg-css3.lg-zoom-in-out .lg-item.lg-next-slide {
  -webkit-transform: scale3d(0, 0, 0);
  transform: scale3d(0, 0, 0); }

.lg-css3.lg-zoom-in-out .lg-item.lg-current {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1; }

.lg-css3.lg-zoom-in-out .lg-item.lg-current, .lg-css3.lg-zoom-in-out .lg-item.lg-next-slide, .lg-css3.lg-zoom-in-out .lg-item.lg-prev-slide {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-soft-zoom .lg-item {
  opacity: 0; }

.lg-css3.lg-soft-zoom .lg-item.lg-prev-slide {
  -webkit-transform: scale3d(1.1, 1.1, 1.1);
  transform: scale3d(1.1, 1.1, 1.1); }

.lg-css3.lg-soft-zoom .lg-item.lg-next-slide {
  -webkit-transform: scale3d(0.9, 0.9, 0.9);
  transform: scale3d(0.9, 0.9, 0.9); }

.lg-css3.lg-soft-zoom .lg-item.lg-current {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1; }

.lg-css3.lg-soft-zoom .lg-item.lg-current, .lg-css3.lg-soft-zoom .lg-item.lg-next-slide, .lg-css3.lg-soft-zoom .lg-item.lg-prev-slide {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-scale-up .lg-item {
  opacity: 0; }

.lg-css3.lg-scale-up .lg-item.lg-next-slide, .lg-css3.lg-scale-up .lg-item.lg-prev-slide {
  -moz-transform: scale3d(0.8, 0.8, 0.8) translate3d(0, 10%, 0);
  -o-transform: scale3d(0.8, 0.8, 0.8) translate3d(0, 10%, 0);
  -ms-transform: scale3d(0.8, 0.8, 0.8) translate3d(0, 10%, 0);
  -webkit-transform: scale3d(0.8, 0.8, 0.8) translate3d(0, 10%, 0);
  transform: scale3d(0.8, 0.8, 0.8) translate3d(0, 10%, 0); }

.lg-css3.lg-scale-up .lg-item.lg-current {
  -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1; }

.lg-css3.lg-scale-up .lg-item.lg-current, .lg-css3.lg-scale-up .lg-item.lg-next-slide, .lg-css3.lg-scale-up .lg-item.lg-prev-slide {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-slide-circular .lg-item {
  opacity: 0; }

.lg-css3.lg-slide-circular .lg-item.lg-prev-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0); }

.lg-css3.lg-slide-circular .lg-item.lg-next-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0); }

.lg-css3.lg-slide-circular .lg-item.lg-current {
  -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1; }

.lg-css3.lg-slide-circular .lg-item.lg-current, .lg-css3.lg-slide-circular .lg-item.lg-next-slide, .lg-css3.lg-slide-circular .lg-item.lg-prev-slide {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-slide-circular-up .lg-item {
  opacity: 0; }

.lg-css3.lg-slide-circular-up .lg-item.lg-prev-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0); }

.lg-css3.lg-slide-circular-up .lg-item.lg-next-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0); }

.lg-css3.lg-slide-circular-up .lg-item.lg-current {
  -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1; }

.lg-css3.lg-slide-circular-up .lg-item.lg-current, .lg-css3.lg-slide-circular-up .lg-item.lg-next-slide, .lg-css3.lg-slide-circular-up .lg-item.lg-prev-slide {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-slide-circular-down .lg-item {
  opacity: 0; }

.lg-css3.lg-slide-circular-down .lg-item.lg-prev-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0); }

.lg-css3.lg-slide-circular-down .lg-item.lg-next-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0); }

.lg-css3.lg-slide-circular-down .lg-item.lg-current {
  -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1; }

.lg-css3.lg-slide-circular-down .lg-item.lg-current, .lg-css3.lg-slide-circular-down .lg-item.lg-next-slide, .lg-css3.lg-slide-circular-down .lg-item.lg-prev-slide {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-slide-circular-vertical .lg-item {
  opacity: 0; }

.lg-css3.lg-slide-circular-vertical .lg-item.lg-prev-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(0, -100%, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(0, -100%, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(0, -100%, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(0, -100%, 0);
  transform: scale3d(0, 0, 0) translate3d(0, -100%, 0); }

.lg-css3.lg-slide-circular-vertical .lg-item.lg-next-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(0, 100%, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(0, 100%, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(0, 100%, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(0, 100%, 0);
  transform: scale3d(0, 0, 0) translate3d(0, 100%, 0); }

.lg-css3.lg-slide-circular-vertical .lg-item.lg-current {
  -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1; }

.lg-css3.lg-slide-circular-vertical .lg-item.lg-current, .lg-css3.lg-slide-circular-vertical .lg-item.lg-next-slide, .lg-css3.lg-slide-circular-vertical .lg-item.lg-prev-slide {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-slide-circular-vertical-left .lg-item {
  opacity: 0; }

.lg-css3.lg-slide-circular-vertical-left .lg-item.lg-prev-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, -100%, 0); }

.lg-css3.lg-slide-circular-vertical-left .lg-item.lg-next-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, 100%, 0); }

.lg-css3.lg-slide-circular-vertical-left .lg-item.lg-current {
  -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1; }

.lg-css3.lg-slide-circular-vertical-left .lg-item.lg-current, .lg-css3.lg-slide-circular-vertical-left .lg-item.lg-next-slide, .lg-css3.lg-slide-circular-vertical-left .lg-item.lg-prev-slide {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-slide-circular-vertical-down .lg-item {
  opacity: 0; }

.lg-css3.lg-slide-circular-vertical-down .lg-item.lg-prev-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, -100%, 0); }

.lg-css3.lg-slide-circular-vertical-down .lg-item.lg-next-slide {
  -moz-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
  -o-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
  -ms-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, 100%, 0); }

.lg-css3.lg-slide-circular-vertical-down .lg-item.lg-current, .lg-css3.lg-slide-vertical-growth .lg-item.lg-current {
  -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0); }

.lg-css3.lg-slide-circular-vertical-down .lg-item.lg-current {
  -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1; }

.lg-css3.lg-slide-circular-vertical-down .lg-item.lg-current, .lg-css3.lg-slide-circular-vertical-down .lg-item.lg-next-slide, .lg-css3.lg-slide-circular-vertical-down .lg-item.lg-prev-slide {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s; }

.lg-css3.lg-slide-vertical .lg-item {
  opacity: 0; }

.lg-css3.lg-slide-vertical .lg-item.lg-prev-slide {
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0); }

.lg-css3.lg-slide-vertical .lg-item.lg-next-slide {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0); }

.lg-css3.lg-slide-vertical .lg-item.lg-current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1; }

.lg-css3.lg-slide-vertical .lg-item.lg-current, .lg-css3.lg-slide-vertical .lg-item.lg-next-slide, .lg-css3.lg-slide-vertical .lg-item.lg-prev-slide {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-vertical-growth .lg-item {
  opacity: 0; }

.lg-css3.lg-slide-vertical-growth .lg-item.lg-prev-slide {
  -moz-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, -150%, 0);
  -o-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, -150%, 0);
  -ms-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, -150%, 0);
  -webkit-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, -150%, 0);
  transform: scale3d(0.5, 0.5, 0.5) translate3d(0, -150%, 0); }

.lg-css3.lg-slide-vertical-growth .lg-item.lg-next-slide {
  -moz-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, 150%, 0);
  -o-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, 150%, 0);
  -ms-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, 150%, 0);
  -webkit-transform: scale3d(0.5, 0.5, 0.5) translate3d(0, 150%, 0);
  transform: scale3d(0.5, 0.5, 0.5) translate3d(0, 150%, 0); }

.lg-css3.lg-slide-vertical-growth .lg-item.lg-current {
  -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1; }

.lg-css3.lg-slide-vertical-growth .lg-item.lg-current, .lg-css3.lg-slide-vertical-growth .lg-item.lg-next-slide, .lg-css3.lg-slide-vertical-growth .lg-item.lg-prev-slide {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-only .lg-item {
  opacity: 0; }

.lg-css3.lg-slide-skew-only .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-only .lg-item.lg-prev-slide {
  -moz-transform: skew(10deg, 0deg);
  -o-transform: skew(10deg, 0deg);
  -ms-transform: skew(10deg, 0deg);
  -webkit-transform: skew(10deg, 0deg);
  transform: skew(10deg, 0deg); }

.lg-css3.lg-slide-skew-only .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg);
  -o-transform: skew(0deg, 0deg);
  -ms-transform: skew(0deg, 0deg);
  -webkit-transform: skew(0deg, 0deg);
  transform: skew(0deg, 0deg);
  opacity: 1; }

.lg-css3.lg-slide-skew-only .lg-item.lg-current, .lg-css3.lg-slide-skew-only .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-only .lg-item.lg-prev-slide {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-only-rev .lg-item {
  opacity: 0; }

.lg-css3.lg-slide-skew-only-rev .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-only-rev .lg-item.lg-prev-slide {
  -moz-transform: skew(-10deg, 0deg);
  -o-transform: skew(-10deg, 0deg);
  -ms-transform: skew(-10deg, 0deg);
  -webkit-transform: skew(-10deg, 0deg);
  transform: skew(-10deg, 0deg); }

.lg-css3.lg-slide-skew-only-rev .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg);
  -o-transform: skew(0deg, 0deg);
  -ms-transform: skew(0deg, 0deg);
  -webkit-transform: skew(0deg, 0deg);
  transform: skew(0deg, 0deg);
  opacity: 1; }

.lg-css3.lg-slide-skew-only-rev .lg-item.lg-current, .lg-css3.lg-slide-skew-only-rev .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-only-rev .lg-item.lg-prev-slide {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-only-y .lg-item {
  opacity: 0; }

.lg-css3.lg-slide-skew-only-y .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-only-y .lg-item.lg-prev-slide {
  -moz-transform: skew(0deg, 10deg);
  -o-transform: skew(0deg, 10deg);
  -ms-transform: skew(0deg, 10deg);
  -webkit-transform: skew(0deg, 10deg);
  transform: skew(0deg, 10deg); }

.lg-css3.lg-slide-skew-only-y .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg);
  -o-transform: skew(0deg, 0deg);
  -ms-transform: skew(0deg, 0deg);
  -webkit-transform: skew(0deg, 0deg);
  transform: skew(0deg, 0deg);
  opacity: 1; }

.lg-css3.lg-slide-skew-only-y .lg-item.lg-current, .lg-css3.lg-slide-skew-only-y .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-only-y .lg-item.lg-prev-slide {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-only-y-rev .lg-item {
  opacity: 0; }

.lg-css3.lg-slide-skew-only-y-rev .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-only-y-rev .lg-item.lg-prev-slide {
  -moz-transform: skew(0deg, -10deg);
  -o-transform: skew(0deg, -10deg);
  -ms-transform: skew(0deg, -10deg);
  -webkit-transform: skew(0deg, -10deg);
  transform: skew(0deg, -10deg); }

.lg-css3.lg-slide-skew-only-y-rev .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg);
  -o-transform: skew(0deg, 0deg);
  -ms-transform: skew(0deg, 0deg);
  -webkit-transform: skew(0deg, 0deg);
  transform: skew(0deg, 0deg);
  opacity: 1; }

.lg-css3.lg-slide-skew-only-y-rev .lg-item.lg-current, .lg-css3.lg-slide-skew-only-y-rev .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-only-y-rev .lg-item.lg-prev-slide {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew .lg-item {
  opacity: 0; }

.lg-css3.lg-slide-skew .lg-item.lg-prev-slide {
  -moz-transform: skew(20deg, 0deg) translate3d(-100%, 0, 0);
  -o-transform: skew(20deg, 0deg) translate3d(-100%, 0, 0);
  -ms-transform: skew(20deg, 0deg) translate3d(-100%, 0, 0);
  -webkit-transform: skew(20deg, 0deg) translate3d(-100%, 0, 0);
  transform: skew(20deg, 0deg) translate3d(-100%, 0, 0); }

.lg-css3.lg-slide-skew .lg-item.lg-next-slide {
  -moz-transform: skew(20deg, 0deg) translate3d(100%, 0, 0);
  -o-transform: skew(20deg, 0deg) translate3d(100%, 0, 0);
  -ms-transform: skew(20deg, 0deg) translate3d(100%, 0, 0);
  -webkit-transform: skew(20deg, 0deg) translate3d(100%, 0, 0);
  transform: skew(20deg, 0deg) translate3d(100%, 0, 0); }

.lg-css3.lg-slide-skew .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg) translate3d(0, 0, 0);
  -o-transform: skew(0deg, 0deg) translate3d(0, 0, 0);
  -ms-transform: skew(0deg, 0deg) translate3d(0, 0, 0);
  -webkit-transform: skew(0deg, 0deg) translate3d(0, 0, 0);
  transform: skew(0deg, 0deg) translate3d(0, 0, 0);
  opacity: 1; }

.lg-css3.lg-slide-skew .lg-item.lg-current, .lg-css3.lg-slide-skew .lg-item.lg-next-slide, .lg-css3.lg-slide-skew .lg-item.lg-prev-slide {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-rev .lg-item {
  opacity: 0; }

.lg-css3.lg-slide-skew-rev .lg-item.lg-prev-slide {
  -moz-transform: skew(-20deg, 0deg) translate3d(-100%, 0, 0);
  -o-transform: skew(-20deg, 0deg) translate3d(-100%, 0, 0);
  -ms-transform: skew(-20deg, 0deg) translate3d(-100%, 0, 0);
  -webkit-transform: skew(-20deg, 0deg) translate3d(-100%, 0, 0);
  transform: skew(-20deg, 0deg) translate3d(-100%, 0, 0); }

.lg-css3.lg-slide-skew-rev .lg-item.lg-next-slide {
  -moz-transform: skew(-20deg, 0deg) translate3d(100%, 0, 0);
  -o-transform: skew(-20deg, 0deg) translate3d(100%, 0, 0);
  -ms-transform: skew(-20deg, 0deg) translate3d(100%, 0, 0);
  -webkit-transform: skew(-20deg, 0deg) translate3d(100%, 0, 0);
  transform: skew(-20deg, 0deg) translate3d(100%, 0, 0); }

.lg-css3.lg-slide-skew-rev .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg) translate3d(0, 0, 0);
  -o-transform: skew(0deg, 0deg) translate3d(0, 0, 0);
  -ms-transform: skew(0deg, 0deg) translate3d(0, 0, 0);
  -webkit-transform: skew(0deg, 0deg) translate3d(0, 0, 0);
  transform: skew(0deg, 0deg) translate3d(0, 0, 0);
  opacity: 1; }

.lg-css3.lg-slide-skew-rev .lg-item.lg-current, .lg-css3.lg-slide-skew-rev .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-rev .lg-item.lg-prev-slide {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-cross .lg-item {
  opacity: 0; }

.lg-css3.lg-slide-skew-cross .lg-item.lg-prev-slide {
  -moz-transform: skew(0deg, 60deg) translate3d(-100%, 0, 0);
  -o-transform: skew(0deg, 60deg) translate3d(-100%, 0, 0);
  -ms-transform: skew(0deg, 60deg) translate3d(-100%, 0, 0);
  -webkit-transform: skew(0deg, 60deg) translate3d(-100%, 0, 0);
  transform: skew(0deg, 60deg) translate3d(-100%, 0, 0); }

.lg-css3.lg-slide-skew-cross .lg-item.lg-next-slide {
  -moz-transform: skew(0deg, 60deg) translate3d(100%, 0, 0);
  -o-transform: skew(0deg, 60deg) translate3d(100%, 0, 0);
  -ms-transform: skew(0deg, 60deg) translate3d(100%, 0, 0);
  -webkit-transform: skew(0deg, 60deg) translate3d(100%, 0, 0);
  transform: skew(0deg, 60deg) translate3d(100%, 0, 0); }

.lg-css3.lg-slide-skew-cross .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg) translate3d(0, 0, 0);
  -o-transform: skew(0deg, 0deg) translate3d(0, 0, 0);
  -ms-transform: skew(0deg, 0deg) translate3d(0, 0, 0);
  -webkit-transform: skew(0deg, 0deg) translate3d(0, 0, 0);
  transform: skew(0deg, 0deg) translate3d(0, 0, 0);
  opacity: 1; }

.lg-css3.lg-slide-skew-cross .lg-item.lg-current, .lg-css3.lg-slide-skew-cross .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-cross .lg-item.lg-prev-slide {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-cross-rev .lg-item {
  opacity: 0; }

.lg-css3.lg-slide-skew-cross-rev .lg-item.lg-prev-slide {
  -moz-transform: skew(0deg, -60deg) translate3d(-100%, 0, 0);
  -o-transform: skew(0deg, -60deg) translate3d(-100%, 0, 0);
  -ms-transform: skew(0deg, -60deg) translate3d(-100%, 0, 0);
  -webkit-transform: skew(0deg, -60deg) translate3d(-100%, 0, 0);
  transform: skew(0deg, -60deg) translate3d(-100%, 0, 0); }

.lg-css3.lg-slide-skew-cross-rev .lg-item.lg-next-slide {
  -moz-transform: skew(0deg, -60deg) translate3d(100%, 0, 0);
  -o-transform: skew(0deg, -60deg) translate3d(100%, 0, 0);
  -ms-transform: skew(0deg, -60deg) translate3d(100%, 0, 0);
  -webkit-transform: skew(0deg, -60deg) translate3d(100%, 0, 0);
  transform: skew(0deg, -60deg) translate3d(100%, 0, 0); }

.lg-css3.lg-slide-skew-cross-rev .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg) translate3d(0, 0, 0);
  -o-transform: skew(0deg, 0deg) translate3d(0, 0, 0);
  -ms-transform: skew(0deg, 0deg) translate3d(0, 0, 0);
  -webkit-transform: skew(0deg, 0deg) translate3d(0, 0, 0);
  transform: skew(0deg, 0deg) translate3d(0, 0, 0);
  opacity: 1; }

.lg-css3.lg-slide-skew-cross-rev .lg-item.lg-current, .lg-css3.lg-slide-skew-cross-rev .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-cross-rev .lg-item.lg-prev-slide {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-ver .lg-item {
  opacity: 0; }

.lg-css3.lg-slide-skew-ver .lg-item.lg-prev-slide {
  -moz-transform: skew(60deg, 0deg) translate3d(0, -100%, 0);
  -o-transform: skew(60deg, 0deg) translate3d(0, -100%, 0);
  -ms-transform: skew(60deg, 0deg) translate3d(0, -100%, 0);
  -webkit-transform: skew(60deg, 0deg) translate3d(0, -100%, 0);
  transform: skew(60deg, 0deg) translate3d(0, -100%, 0); }

.lg-css3.lg-slide-skew-ver .lg-item.lg-next-slide {
  -moz-transform: skew(60deg, 0deg) translate3d(0, 100%, 0);
  -o-transform: skew(60deg, 0deg) translate3d(0, 100%, 0);
  -ms-transform: skew(60deg, 0deg) translate3d(0, 100%, 0);
  -webkit-transform: skew(60deg, 0deg) translate3d(0, 100%, 0);
  transform: skew(60deg, 0deg) translate3d(0, 100%, 0); }

.lg-css3.lg-slide-skew-ver .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg) translate3d(0, 0, 0);
  -o-transform: skew(0deg, 0deg) translate3d(0, 0, 0);
  -ms-transform: skew(0deg, 0deg) translate3d(0, 0, 0);
  -webkit-transform: skew(0deg, 0deg) translate3d(0, 0, 0);
  transform: skew(0deg, 0deg) translate3d(0, 0, 0);
  opacity: 1; }

.lg-css3.lg-slide-skew-ver .lg-item.lg-current, .lg-css3.lg-slide-skew-ver .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-ver .lg-item.lg-prev-slide {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-ver-rev .lg-item {
  opacity: 0; }

.lg-css3.lg-slide-skew-ver-rev .lg-item.lg-prev-slide {
  -moz-transform: skew(-60deg, 0deg) translate3d(0, -100%, 0);
  -o-transform: skew(-60deg, 0deg) translate3d(0, -100%, 0);
  -ms-transform: skew(-60deg, 0deg) translate3d(0, -100%, 0);
  -webkit-transform: skew(-60deg, 0deg) translate3d(0, -100%, 0);
  transform: skew(-60deg, 0deg) translate3d(0, -100%, 0); }

.lg-css3.lg-slide-skew-ver-rev .lg-item.lg-next-slide {
  -moz-transform: skew(-60deg, 0deg) translate3d(0, 100%, 0);
  -o-transform: skew(-60deg, 0deg) translate3d(0, 100%, 0);
  -ms-transform: skew(-60deg, 0deg) translate3d(0, 100%, 0);
  -webkit-transform: skew(-60deg, 0deg) translate3d(0, 100%, 0);
  transform: skew(-60deg, 0deg) translate3d(0, 100%, 0); }

.lg-css3.lg-slide-skew-ver-rev .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg) translate3d(0, 0, 0);
  -o-transform: skew(0deg, 0deg) translate3d(0, 0, 0);
  -ms-transform: skew(0deg, 0deg) translate3d(0, 0, 0);
  -webkit-transform: skew(0deg, 0deg) translate3d(0, 0, 0);
  transform: skew(0deg, 0deg) translate3d(0, 0, 0);
  opacity: 1; }

.lg-css3.lg-slide-skew-ver-rev .lg-item.lg-current, .lg-css3.lg-slide-skew-ver-rev .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-ver-rev .lg-item.lg-prev-slide {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-ver-cross .lg-item {
  opacity: 0; }

.lg-css3.lg-slide-skew-ver-cross .lg-item.lg-prev-slide {
  -moz-transform: skew(0deg, 20deg) translate3d(0, -100%, 0);
  -o-transform: skew(0deg, 20deg) translate3d(0, -100%, 0);
  -ms-transform: skew(0deg, 20deg) translate3d(0, -100%, 0);
  -webkit-transform: skew(0deg, 20deg) translate3d(0, -100%, 0);
  transform: skew(0deg, 20deg) translate3d(0, -100%, 0); }

.lg-css3.lg-slide-skew-ver-cross .lg-item.lg-next-slide {
  -moz-transform: skew(0deg, 20deg) translate3d(0, 100%, 0);
  -o-transform: skew(0deg, 20deg) translate3d(0, 100%, 0);
  -ms-transform: skew(0deg, 20deg) translate3d(0, 100%, 0);
  -webkit-transform: skew(0deg, 20deg) translate3d(0, 100%, 0);
  transform: skew(0deg, 20deg) translate3d(0, 100%, 0); }

.lg-css3.lg-slide-skew-ver-cross .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg) translate3d(0, 0, 0);
  -o-transform: skew(0deg, 0deg) translate3d(0, 0, 0);
  -ms-transform: skew(0deg, 0deg) translate3d(0, 0, 0);
  -webkit-transform: skew(0deg, 0deg) translate3d(0, 0, 0);
  transform: skew(0deg, 0deg) translate3d(0, 0, 0);
  opacity: 1; }

.lg-css3.lg-slide-skew-ver-cross .lg-item.lg-current, .lg-css3.lg-slide-skew-ver-cross .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-ver-cross .lg-item.lg-prev-slide {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide-skew-ver-cross-rev .lg-item {
  opacity: 0; }

.lg-css3.lg-slide-skew-ver-cross-rev .lg-item.lg-prev-slide {
  -moz-transform: skew(0deg, -20deg) translate3d(0, -100%, 0);
  -o-transform: skew(0deg, -20deg) translate3d(0, -100%, 0);
  -ms-transform: skew(0deg, -20deg) translate3d(0, -100%, 0);
  -webkit-transform: skew(0deg, -20deg) translate3d(0, -100%, 0);
  transform: skew(0deg, -20deg) translate3d(0, -100%, 0); }

.lg-css3.lg-slide-skew-ver-cross-rev .lg-item.lg-next-slide {
  -moz-transform: skew(0deg, -20deg) translate3d(0, 100%, 0);
  -o-transform: skew(0deg, -20deg) translate3d(0, 100%, 0);
  -ms-transform: skew(0deg, -20deg) translate3d(0, 100%, 0);
  -webkit-transform: skew(0deg, -20deg) translate3d(0, 100%, 0);
  transform: skew(0deg, -20deg) translate3d(0, 100%, 0); }

.lg-css3.lg-slide-skew-ver-cross-rev .lg-item.lg-current {
  -moz-transform: skew(0deg, 0deg) translate3d(0, 0, 0);
  -o-transform: skew(0deg, 0deg) translate3d(0, 0, 0);
  -ms-transform: skew(0deg, 0deg) translate3d(0, 0, 0);
  -webkit-transform: skew(0deg, 0deg) translate3d(0, 0, 0);
  transform: skew(0deg, 0deg) translate3d(0, 0, 0);
  opacity: 1; }

.lg-css3.lg-slide-skew-ver-cross-rev .lg-item.lg-current, .lg-css3.lg-slide-skew-ver-cross-rev .lg-item.lg-next-slide, .lg-css3.lg-slide-skew-ver-cross-rev .lg-item.lg-prev-slide {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-lollipop .lg-item {
  opacity: 0; }

.lg-css3.lg-lollipop .lg-item.lg-prev-slide {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0); }

.lg-css3.lg-lollipop .lg-item.lg-next-slide {
  -moz-transform: translate3d(0, 0, 0) scale(0.5);
  -o-transform: translate3d(0, 0, 0) scale(0.5);
  -ms-transform: translate3d(0, 0, 0) scale(0.5);
  -webkit-transform: translate3d(0, 0, 0) scale(0.5);
  transform: translate3d(0, 0, 0) scale(0.5); }

.lg-css3.lg-lollipop .lg-item.lg-current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1; }

.lg-css3.lg-lollipop .lg-item.lg-current, .lg-css3.lg-lollipop .lg-item.lg-next-slide, .lg-css3.lg-lollipop .lg-item.lg-prev-slide {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-lollipop-rev .lg-item {
  opacity: 0; }

.lg-css3.lg-lollipop-rev .lg-item.lg-prev-slide {
  -moz-transform: translate3d(0, 0, 0) scale(0.5);
  -o-transform: translate3d(0, 0, 0) scale(0.5);
  -ms-transform: translate3d(0, 0, 0) scale(0.5);
  -webkit-transform: translate3d(0, 0, 0) scale(0.5);
  transform: translate3d(0, 0, 0) scale(0.5); }

.lg-css3.lg-lollipop-rev .lg-item.lg-next-slide {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0); }

.lg-css3.lg-lollipop-rev .lg-item.lg-current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1; }

.lg-css3.lg-lollipop-rev .lg-item.lg-current, .lg-css3.lg-lollipop-rev .lg-item.lg-next-slide, .lg-css3.lg-lollipop-rev .lg-item.lg-prev-slide {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-rotate .lg-item {
  opacity: 0; }

.lg-css3.lg-rotate .lg-item.lg-prev-slide {
  -moz-transform: rotate(-360deg);
  -o-transform: rotate(-360deg);
  -ms-transform: rotate(-360deg);
  -webkit-transform: rotate(-360deg);
  transform: rotate(-360deg); }

.lg-css3.lg-rotate .lg-item.lg-next-slide {
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg); }

.lg-css3.lg-rotate .lg-item.lg-current {
  -moz-transform: rotate(0);
  -o-transform: rotate(0);
  -ms-transform: rotate(0);
  -webkit-transform: rotate(0);
  transform: rotate(0);
  opacity: 1; }

.lg-css3.lg-rotate .lg-item.lg-current, .lg-css3.lg-rotate .lg-item.lg-next-slide, .lg-css3.lg-rotate .lg-item.lg-prev-slide {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-rotate-rev .lg-item {
  opacity: 0; }

.lg-css3.lg-rotate-rev .lg-item.lg-prev-slide {
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg); }

.lg-css3.lg-rotate-rev .lg-item.lg-next-slide {
  -moz-transform: rotate(-360deg);
  -o-transform: rotate(-360deg);
  -ms-transform: rotate(-360deg);
  -webkit-transform: rotate(-360deg);
  transform: rotate(-360deg); }

.lg-css3.lg-rotate-rev .lg-item.lg-current {
  -moz-transform: rotate(0);
  -o-transform: rotate(0);
  -ms-transform: rotate(0);
  -webkit-transform: rotate(0);
  transform: rotate(0);
  opacity: 1; }

.lg-css3.lg-rotate-rev .lg-item.lg-current, .lg-css3.lg-rotate-rev .lg-item.lg-next-slide, .lg-css3.lg-rotate-rev .lg-item.lg-prev-slide {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-tube .lg-item {
  opacity: 0; }

.lg-css3.lg-tube .lg-item.lg-prev-slide {
  -moz-transform: scale3d(1, 0, 1) translate3d(-100%, 0, 0);
  -o-transform: scale3d(1, 0, 1) translate3d(-100%, 0, 0);
  -ms-transform: scale3d(1, 0, 1) translate3d(-100%, 0, 0);
  -webkit-transform: scale3d(1, 0, 1) translate3d(-100%, 0, 0);
  transform: scale3d(1, 0, 1) translate3d(-100%, 0, 0); }

.lg-css3.lg-tube .lg-item.lg-next-slide {
  -moz-transform: scale3d(1, 0, 1) translate3d(100%, 0, 0);
  -o-transform: scale3d(1, 0, 1) translate3d(100%, 0, 0);
  -ms-transform: scale3d(1, 0, 1) translate3d(100%, 0, 0);
  -webkit-transform: scale3d(1, 0, 1) translate3d(100%, 0, 0);
  transform: scale3d(1, 0, 1) translate3d(100%, 0, 0); }

.lg-css3.lg-tube .lg-item.lg-current {
  -moz-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -o-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -ms-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1; }

.lg-css3.lg-tube .lg-item.lg-current, .lg-css3.lg-tube .lg-item.lg-next-slide, .lg-css3.lg-tube .lg-item.lg-prev-slide {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-outer.fb-comments .fb-comments {
  height: 100%;
  overflow-y: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 420px;
  z-index: 99999;
  background: url(../img/loading.gif) center center no-repeat #fff; }

.lg-outer.fb-comments .fb-comments.fb_iframe_widget {
  background-image: none; }

.lg-outer.fb-comments .fb-comments.fb_iframe_widget.fb_iframe_widget_loader {
  background: url(../img/loading.gif) center center no-repeat #fff; }

.lg-outer.fb-comments .lg-toolbar {
  right: 420px;
  width: auto; }

.lg-outer.fb-comments .lg-actions .lg-next {
  right: 420px; }

.lg-outer.fb-comments .lg-item, .lg-outer.fb-comments .lg-item.lg-complete .lg-img-wrap {
  background-image: none; }

.lg-outer.fb-comments .lg-img-wrap {
  padding-right: 400px !important;
  background: url(../img/loading.gif) center center no-repeat; }

.lg-outer.fb-comments .lg-sub-html {
  padding: 0;
  position: static; }

/*!
 * Bootstrap-select v1.13.18 (https://developer.snapappointments.com/bootstrap-select)
 *
 * Copyright 2012-2020 SnapAppointments, LLC
 * Licensed under MIT (https://github.com/snapappointments/bootstrap-select/blob/master/LICENSE)
 */
@-webkit-keyframes bs-notify-fadeOut {
  0% {
    opacity: .9; }
  100% {
    opacity: 0; } }

@-o-keyframes bs-notify-fadeOut {
  0% {
    opacity: .9; }
  100% {
    opacity: 0; } }

@keyframes bs-notify-fadeOut {
  0% {
    opacity: .9; }
  100% {
    opacity: 0; } }

.bootstrap-select > select.bs-select-hidden, select.bs-select-hidden, select.selectpicker {
  display: none !important; }

.bootstrap-select {
  width: 220px\0;
  vertical-align: middle; }

.bootstrap-select > .dropdown-toggle {
  position: relative;
  width: 100%;
  text-align: right;
  white-space: nowrap;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.bootstrap-select > .dropdown-toggle:after {
  margin-top: -1px; }

.bootstrap-select > .dropdown-toggle.bs-placeholder, .bootstrap-select > .dropdown-toggle.bs-placeholder:active, .bootstrap-select > .dropdown-toggle.bs-placeholder:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder:hover {
  color: #999; }

.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:active, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:active, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:active, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:active, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:active, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:active, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:hover {
  color: rgba(255, 255, 255, 0.5); }

.bootstrap-select > select {
  position: absolute !important;
  bottom: 0;
  left: 50%;
  display: block !important;
  width: 0.5px !important;
  height: 100% !important;
  padding: 0 !important;
  opacity: 0 !important;
  border: none;
  z-index: 0 !important; }

.bootstrap-select > select.mobile-device {
  top: 0;
  left: 0;
  display: block !important;
  width: 100% !important;
  z-index: 2 !important; }

.bootstrap-select.is-invalid .dropdown-toggle, .error .bootstrap-select .dropdown-toggle, .has-error .bootstrap-select .dropdown-toggle, .was-validated .bootstrap-select select:invalid + .dropdown-toggle {
  border-color: #b94a48; }

.bootstrap-select.is-valid .dropdown-toggle, .was-validated .bootstrap-select select:valid + .dropdown-toggle {
  border-color: #28a745; }

.bootstrap-select.fit-width {
  width: auto !important; }

.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: 220px; }

.bootstrap-select .dropdown-toggle:focus, .bootstrap-select > select.mobile-device:focus + .dropdown-toggle {
  outline: thin dotted #333 !important;
  outline: 5px auto -webkit-focus-ring-color !important;
  outline-offset: -2px; }

.bootstrap-select.form-control {
  margin-bottom: 0;
  padding: 0;
  border: none;
  height: auto; }

:not(.input-group) > .bootstrap-select.form-control:not([class*=col-]) {
  width: 100%; }

.bootstrap-select.form-control.input-group-btn {
  float: none;
  z-index: auto; }

.form-inline .bootstrap-select, .form-inline .bootstrap-select.form-control:not([class*=col-]) {
  width: auto; }

.bootstrap-select:not(.input-group-btn), .bootstrap-select[class*=col-] {
  float: none;
  display: inline-block;
  margin-left: 0; }

.bootstrap-select.dropdown-menu-right, .bootstrap-select[class*=col-].dropdown-menu-right, .row .bootstrap-select[class*=col-].dropdown-menu-right {
  float: right; }

.form-group .bootstrap-select, .form-horizontal .bootstrap-select, .form-inline .bootstrap-select {
  margin-bottom: 0; }

.form-group-lg .bootstrap-select.form-control, .form-group-sm .bootstrap-select.form-control {
  padding: 0; }

.form-group-lg .bootstrap-select.form-control .dropdown-toggle, .form-group-sm .bootstrap-select.form-control .dropdown-toggle {
  height: 100%;
  font-size: inherit;
  line-height: inherit;
  border-radius: inherit; }

.bootstrap-select.form-control-lg .dropdown-toggle, .bootstrap-select.form-control-sm .dropdown-toggle {
  font-size: inherit;
  line-height: inherit;
  border-radius: inherit; }

.bootstrap-select.form-control-sm .dropdown-toggle {
  padding: .25rem .5rem; }

.bootstrap-select.form-control-lg .dropdown-toggle {
  padding: .5rem 1rem; }

.form-inline .bootstrap-select .form-control {
  width: 100%; }

.bootstrap-select.disabled, .bootstrap-select > .disabled {
  cursor: not-allowed; }

.bootstrap-select.disabled:focus, .bootstrap-select > .disabled:focus {
  outline: 0 !important; }

.bootstrap-select.bs-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 0 !important;
  padding: 0 !important; }

.bootstrap-select.bs-container .dropdown-menu {
  z-index: 1060; }

.bootstrap-select .dropdown-toggle .filter-option {
  position: static;
  top: 0;
  left: 0;
  float: left;
  height: 100%;
  width: 100%;
  text-align: left;
  overflow: hidden;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto; }

.bs3.bootstrap-select .dropdown-toggle .filter-option {
  padding-right: inherit; }

.input-group .bs3-has-addon.bootstrap-select .dropdown-toggle .filter-option {
  position: absolute;
  padding-top: inherit;
  padding-bottom: inherit;
  padding-left: inherit;
  float: none; }

.input-group .bs3-has-addon.bootstrap-select .dropdown-toggle .filter-option .filter-option-inner {
  padding-right: inherit; }

.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
  overflow: hidden; }

.bootstrap-select .dropdown-toggle .filter-expand {
  width: 0 !important;
  float: left;
  opacity: 0 !important;
  overflow: hidden; }

.bootstrap-select .dropdown-toggle .caret {
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -2px;
  vertical-align: middle; }

.input-group .bootstrap-select.form-control .dropdown-toggle {
  border-radius: inherit; }

.bootstrap-select[class*=col-] .dropdown-toggle {
  width: 100%; }

.bootstrap-select .dropdown-menu {
  min-width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.bootstrap-select .dropdown-menu > .inner:focus {
  outline: 0 !important; }

.bootstrap-select .dropdown-menu.inner {
  position: static;
  float: none;
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none; }

.bootstrap-select .dropdown-menu li {
  position: relative; }

.bootstrap-select .dropdown-menu li.active small {
  color: rgba(255, 255, 255, 0.5) !important; }

.bootstrap-select .dropdown-menu li.disabled a {
  cursor: not-allowed; }

.bootstrap-select .dropdown-menu li a {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.bootstrap-select .dropdown-menu li a.opt {
  position: relative;
  padding-left: 2.25em; }

.bootstrap-select .dropdown-menu li a span.check-mark {
  display: none; }

.bootstrap-select .dropdown-menu li a span.text {
  display: inline-block; }

.bootstrap-select .dropdown-menu li small {
  padding-left: .5em; }

.bootstrap-select .dropdown-menu .notify {
  position: absolute;
  bottom: 5px;
  width: 96%;
  margin: 0 2%;
  min-height: 26px;
  padding: 3px 5px;
  background: #f5f5f5;
  border: 1px solid #e3e3e3;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  pointer-events: none;
  opacity: .9;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.bootstrap-select .dropdown-menu .notify.fadeOut {
  -webkit-animation: .3s linear 750ms forwards bs-notify-fadeOut;
  -o-animation: .3s linear 750ms forwards bs-notify-fadeOut;
  animation: .3s linear 750ms forwards bs-notify-fadeOut; }

.bootstrap-select .no-results {
  padding: 3px;
  background: #f5f5f5;
  margin: 0 5px;
  white-space: nowrap; }

.bootstrap-select.fit-width .dropdown-toggle .filter-option {
  position: static;
  display: inline;
  padding: 0; }

.bootstrap-select.fit-width .dropdown-toggle .filter-option-inner, .bootstrap-select.fit-width .dropdown-toggle .filter-option-inner-inner {
  display: inline; }

.bootstrap-select.fit-width .dropdown-toggle .bs-caret:before {
  content: '\00a0'; }

.bootstrap-select.fit-width .dropdown-toggle .caret {
  position: static;
  top: auto;
  margin-top: -1px; }

.bootstrap-select.show-tick .dropdown-menu .selected span.check-mark {
  position: absolute;
  display: inline-block;
  right: 15px;
  top: 5px; }

.bootstrap-select.show-tick .dropdown-menu li a span.text {
  margin-right: 34px; }

.bootstrap-select .bs-ok-default:after {
  content: '';
  display: block;
  width: .5em;
  height: 1em;
  border-style: solid;
  border-width: 0 .26em .26em 0;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg); }

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle, .bootstrap-select.show-menu-arrow.show > .dropdown-toggle {
  z-index: 1061; }

.bootstrap-select.show-menu-arrow .dropdown-toggle .filter-option:before {
  content: '';
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid rgba(204, 204, 204, 0.2);
  position: absolute;
  bottom: -4px;
  left: 9px;
  display: none; }

.bootstrap-select.show-menu-arrow .dropdown-toggle .filter-option:after {
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  position: absolute;
  bottom: -4px;
  left: 10px;
  display: none; }

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle .filter-option:before {
  bottom: auto;
  top: -4px;
  border-top: 7px solid rgba(204, 204, 204, 0.2);
  border-bottom: 0; }

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle .filter-option:after {
  bottom: auto;
  top: -4px;
  border-top: 6px solid #fff;
  border-bottom: 0; }

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle .filter-option:before {
  right: 12px;
  left: auto; }

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle .filter-option:after {
  right: 13px;
  left: auto; }

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle .filter-option:after, .bootstrap-select.show-menu-arrow.open > .dropdown-toggle .filter-option:before, .bootstrap-select.show-menu-arrow.show > .dropdown-toggle .filter-option:after, .bootstrap-select.show-menu-arrow.show > .dropdown-toggle .filter-option:before {
  display: block; }

.bs-actionsbox, .bs-donebutton, .bs-searchbox {
  padding: 4px 8px; }

.bs-actionsbox {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.bs-actionsbox .btn-group button {
  width: 50%; }

.bs-donebutton {
  float: left;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.bs-donebutton .btn-group button {
  width: 100%; }

.bs-searchbox + .bs-actionsbox {
  padding: 0 8px 4px; }

.bs-searchbox .form-control {
  margin-bottom: 0;
  width: 100%;
  float: none; }

@font-face {
  font-family: 'lg';
  src: url("../fonts/lg.ttf") format("truetype"), url("../fonts/lg.woff?22t19m") format("woff"), url("../fonts/lg.svg?22t19m#lg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

.lg-download.lg-icon {
  display: none !important; }

.lg-outer .lg-has-vimeo .lg-video-play {
  background: url("../images/useful/lightgallery/vimeo-play.png") no-repeat scroll 0 0 transparent; }

.lg-outer .lg-has-vimeo:hover .lg-video-play {
  background: url("../images/useful/lightgallery/vimeo-play.png") no-repeat scroll 0 -58px transparent; }

.lg-outer .lg-has-html5 .lg-video-play {
  background: transparent url("../images/useful/lightgallery/video-play.png") no-repeat scroll 0 0; }

.lg-outer .lg-has-youtube .lg-video-play {
  background: url("../images/useful/lightgallery/youtube-play.png") no-repeat scroll 0 0 transparent; }

.lg-outer .lg-has-youtube:hover .lg-video-play {
  background: url("../images/useful/lightgallery/youtube-play.png") no-repeat scroll 0 -60px transparent; }

.lg-outer .lg-item {
  background: url("../images/useful/lightgallery/loading.gif") no-repeat scroll center center transparent; }

.lg-backdrop.in {
  background: #7C7C7C;
  opacity: 0.9; }

.lg-toolbar {
  background: transparent; }

.lg-toolbar .lg-icon, .lg-actions .lg-next, .lg-actions .lg-prev {
  color: white; }

.bootstrap-select .dropdown-toggle {
  background-color: #EEEEEE !important;
  position: relative;
  padding: 1.5rem 1rem;
  border: none;
  border-bottom: 2px solid #0A0A0A; }
  .bootstrap-select .dropdown-toggle:focus {
    outline: none !important;
    outline-offset: 0;
    box-shadow: none !important; }
  .bootstrap-select .dropdown-toggle::after {
    content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xLjQxIDAuNTkwMDI2TDYgNS4xNzAwM0wxMC41OSAwLjU5MDAyN0wxMiAyLjAwMDAzTDYgOC4wMDAwM0wtNi4xNjMzMWUtMDggMi4wMDAwM0wxLjQxIDAuNTkwMDI2WiIgZmlsbD0iIzBBMEEwQSIvPgo8L3N2Zz4K);
    transform: translateY(-50%);
    display: inline-block;
    margin-left: 0.255em;
    position: absolute;
    right: 2.2rem;
    border: none;
    top: 50%;
    transition: all 0.3s ease; }
  .bootstrap-select .dropdown-toggle .filter-option-inner-inner {
    font-size: 1.6rem;
    text-transform: capitalize;
    color: #0A0A0A;
    font-weight: 500; }

.bootstrap-select.show .dropdown-toggle:after {
  transform: rotateX(180deg) translateY(45%); }

.bootstrap-select .dropdown-menu.show {
  top: 0 !important;
  border-top: 0;
  border-radius: 0; }
  .bootstrap-select .dropdown-menu.show .dropdown-item {
    padding: 1rem; }
  .bootstrap-select .dropdown-menu.show .dropdown-item:active {
    background-color: transparent;
    color: #EC6B41; }
  .bootstrap-select .dropdown-menu.show .dropdown-item.active {
    background-color: #EC6B41; }
    .bootstrap-select .dropdown-menu.show .dropdown-item.active:active {
      color: white; }
  .bootstrap-select .dropdown-menu.show .dropdown-item:hover,
  .bootstrap-select .dropdown-menu.show .dropdown-item:focus {
    color: #EC6B41;
    outline: none; }
  .bootstrap-select .dropdown-menu.show .dropdown-item.active:hover,
  .bootstrap-select .dropdown-menu.show .dropdown-item.active:focus {
    color: white;
    outline: none; }

.h-reset-list {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0; }

html {
  height: 100%;
  font-size: 10px; }

body {
  position: relative;
  min-height: 100%;
  letter-spacing: .015rem;
  font-weight: 400 !important;
  font-family: 'Raleway' , sans-serif !important; }
  body.has-pt main {
    padding-top: 12.6rem; }
    @media (max-width: 1199.98px) {
      body.has-pt main {
        padding-top: 8rem; } }
    @media (max-width: 499.98px) {
      body.has-pt main {
        padding-top: 6rem; } }
  body.modal-open {
    padding-right: 0 !important; }
  body:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(124, 124, 124, 0.7);
    opacity: 0;
    transition: all 0.3s ease;
    visibility: hidden;
    z-index: 99; }
  body.fixed {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: scroll; }
    body.fixed:after {
      opacity: 1;
      visibility: visible; }

img {
  max-width: 100%;
  height: auto; }

main {
  flex-grow: 1; }

.h-object-fit {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
  display: block; }
  .h-object-fit > img {
    position: absolute;
    max-height: 100%;
    max-width: 100%;
    margin: auto;
    height: auto;
    width: 100%;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    object-fit: cover;
    max-height: none;
    max-width: none;
    height: 100%; }
    .no-object-fit .h-object-fit > img,
    .no-objectfit .h-object-fit > img {
      display: none; }

.l-wrapper {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column; }

.container {
  padding: 0 2rem; }

@keyframes playWawe {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor; }

/* ==========================================
  Single-colored icons can be modified like so:
  .icon-name {
    font-size: 32px;
    color: red;
  }
  ========================================== */
.icon-logo {
  width: 8em; }

.icon-concrete {
  width: 1.0712890625em; }

.icon-lease {
  width: 1.478515625em; }

.icon-loose {
  width: 1.375em; }

.icon-arrow {
  width: 1.25em; }

.icon-arrowtwo {
  width: 1.42857143em; }

.icon-beton {
  width: 4.55045872em; }

.icon-pdf {
  width: 0.766666667em; }

.icon-nav, .icon-clock2 {
  color: transparent !important; }

button {
  display: inline-block;
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent; }
  button:hover, button:focus, button:active {
    outline: none; }

.btn {
  display: inline-block;
  font-weight: 500;
  color: inherit;
  transition: all 0.3s ease;
  text-transform: uppercase;
  font-size: 1.6rem;
  letter-spacing: normal;
  line-height: normal;
  color: white; }
  @media (max-width: 499.98px) {
    .btn {
      font-size: 1.4rem;
      font-weight: 400; } }
  .btn--accent {
    background-color: #EC6B41;
    padding: 1.85rem 4.9rem; }
    @media (max-width: 499.98px) {
      .btn--accent {
        padding: 1.5rem 2rem; } }
    .btn--accent:hover {
      background-color: #ea592a;
      color: white; }
  .btn--outline {
    border: .2rem solid white;
    padding: 1.75rem 3.5rem; }
    @media (max-width: 499.98px) {
      .btn--outline {
        padding: 1.4rem 2rem; } }
    .btn--outline:hover {
      border: 0.2rem solid #EC6B41;
      color: white; }
  .btn--ico {
    display: inline-flex;
    align-items: center;
    justify-content: center; }
    .btn--ico .icon {
      margin-left: 1.2rem;
      color: white;
      transition: all 0.3s ease; }

.form-control::placeholder {
  transition: opacity 0.3s ease;
  opacity: 1; }

.form-control:focus::placeholder {
  opacity: 0; }

.form-control.error {
  border-color: #ff0000 !important;
  background-color: #ffdede; }

.form-control:disabled .form-control, .form-control[disabled] .form-control, .form-control.disabled .form-control {
  pointer-events: none;
  cursor: default;
  opacity: 0.5; }

.form-group:disabled .form-control, .form-group[disabled] .form-control, .form-group.disabled .form-control {
  pointer-events: none;
  cursor: default;
  opacity: 0.5; }

.input-wrap,
.select-wrap {
  position: relative; }

.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: 100%; }

.bootstrap-select > .dropdown-toggle.dropdown-toggle, .bootstrap-select > .dropdown-toggle.dropdown-toggle:hover, .bootstrap-select > .dropdown-toggle.dropdown-toggle:focus, .bootstrap-select > .dropdown-toggle.dropdown-toggle:active, .bootstrap-select > .dropdown-toggle.bs-placeholder, .bootstrap-select > .dropdown-toggle.bs-placeholder:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder:active {
  outline: none !important; }

label {
  margin-bottom: 0;
  font-weight: 400;
  cursor: pointer; }
  label.error {
    color: #ff0000; }

.hidden-input {
  visibility: hidden !important;
  position: absolute !important;
  z-index: -1 !important;
  opacity: 0 !important;
  height: 0 !important;
  width: 0 !important;
  left: 0 !important;
  top: 0 !important; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.field_clear_btn {
  transition: color 0.3s ease;
  margin-bottom: auto;
  position: absolute;
  margin-top: auto;
  display: block;
  color: #d8d8d8;
  height: 30px;
  width: 30px;
  bottom: 0;
  right: 0;
  top: 0; }
  .field_clear_btn:hover {
    border-color: #b61f27;
    color: #b61f27; }
  .field_clear_btn:before, .field_clear_btn:after {
    background-color: currentColor;
    position: absolute;
    display: block;
    margin: auto;
    content: '';
    height: 2px;
    width: 16px;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0; }
  .field_clear_btn:before {
    transform: rotate(45deg); }
  .field_clear_btn:after {
    transform: rotate(-45deg); }

.checkbox-label {
  position: relative;
  padding-left: 30px; }
  .checkbox-label:disabled, .checkbox-label[disabled], .checkbox-label.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.5; }
  .checkbox-label .check-icon {
    border: solid 1px #979797;
    border-radius: 4px;
    position: absolute;
    display: block;
    height: 18px;
    width: 18px;
    left: 0;
    top: 2px; }
    .checkbox-label .check-icon:before {
      transform: rotate(-52.5deg) skew(-15deg);
      transition: opacity 0.3s ease;
      border-bottom: solid 3px #b61f27;
      border-left: solid 3px #b61f27;
      position: absolute;
      display: block;
      margin: auto;
      content: '';
      height: 9px;
      width: 15px;
      opacity: 0;
      top: -10px;
      left: 5px;
      bottom: 0;
      right: 0; }
  .checkbox-label .check-value {
    transition: color 0.3s ease;
    color: #323232; }
  .checkbox-label.label_grey .check-value {
    color: #979797; }
  .checkbox-label input:checked ~ .check-icon:before {
    opacity: 1; }
  .checkbox-label input:disabled ~ .check-icon {
    pointer-events: none;
    cursor: default;
    opacity: 0.5; }
  .checkbox-label input:disabled ~ .check-value {
    pointer-events: none;
    cursor: default;
    opacity: 0.5; }

.radio-label {
  padding-left: 20px;
  position: relative; }
  .radio-label:disabled, .radio-label[disabled], .radio-label.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.5; }
  .radio-label .check-icon {
    border: solid 1px #0171a6;
    border-radius: 50%;
    position: absolute;
    display: block;
    height: 13px;
    width: 13px;
    top: 4px;
    left: 0; }
    .radio-label .check-icon:before {
      transition: opacity 0.3s ease;
      background-color: #0171a6;
      border-radius: 50%;
      position: absolute;
      display: block;
      margin: auto;
      content: '';
      height: 5px;
      width: 5px;
      opacity: 0;
      bottom: 0;
      right: 0;
      left: 0;
      top: 0; }
  .radio-label .check-value {
    transition: color 0.3s ease;
    color: #979797; }
  .radio-label.label_grey .check-value {
    color: #979797; }
  .radio-label input:checked ~ .check-icon:before {
    opacity: 1; }
  .radio-label input:disabled ~ .check-icon {
    pointer-events: none;
    cursor: default;
    opacity: 0.5; }
  .radio-label input:disabled ~ .check-value {
    pointer-events: none;
    cursor: default;
    opacity: 0.5; }

.c-footer {
  padding: 5.4rem 0 4rem;
  background-color: #EEEEEE; }
  .c-footer__logo {
    margin-bottom: .8rem;
    display: inline-block; }
    @media (max-width: 499.98px) {
      .c-footer__logo {
        display: flex;
        justify-content: center; } }
  .c-footer-list {
    margin-top: 2.3rem;
    display: flex;
    flex-direction: column; }
    @media (max-width: 499.98px) {
      .c-footer-list {
        margin-top: 1rem;
        align-items: center; } }
    .c-footer-list__item {
      margin-bottom: 1.8rem; }
      @media (max-width: 991.98px) {
        .c-footer-list__item {
          margin-bottom: 1rem; } }
      @media (max-width: 499.98px) {
        .c-footer-list__item {
          margin-bottom: 1rem; } }
    .c-footer-list__txt {
      font-size: 1.4rem;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #0A0A0A;
      font-weight: 600;
      display: flex;
      transition: all 0.3s ease; }
  .c-footer__title {
    font-weight: 900;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: #0A0A0A; }
    @media (max-width: 499.98px) {
      .c-footer__title {
        text-align: center; } }
  .c-footer__category {
    display: flex;
    flex-direction: column;
    padding-top: .5rem; }
    @media (max-width: 991.98px) {
      .c-footer__category {
        margin-bottom: 1rem;
        margin-top: 1rem; } }
  @media (max-width: 991.98px) {
    .c-footer__main-info {
      margin-bottom: 2rem; } }
  .c-footer .row > *:last-child > .c-footer__category {
    margin-left: auto;
    text-align: right; }
    @media (max-width: 991.98px) {
      .c-footer .row > *:last-child > .c-footer__category {
        margin-left: 0;
        text-align: left; } }
    .c-footer .row > *:last-child > .c-footer__category .c-footer-list {
      align-items: flex-end; }
      @media (max-width: 991.98px) {
        .c-footer .row > *:last-child > .c-footer__category .c-footer-list {
          align-items: flex-start; } }
      @media (max-width: 499.98px) {
        .c-footer .row > *:last-child > .c-footer__category .c-footer-list {
          align-items: center; } }
  .c-footer__main {
    padding-bottom: 1rem; }
  .c-footer__bottom {
    padding: 4rem 0 0;
    display: flex;
    justify-content: space-between; }
    @media (max-width: 499.98px) {
      .c-footer__bottom {
        flex-direction: column; } }
  .c-footer__copy {
    font-size: 1.4rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #0A0A0A;
    font-weight: 500; }
    @media (max-width: 499.98px) {
      .c-footer__copy {
        text-align: center; } }
  .c-footer__developed {
    font-size: 1.4rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #0A0A0A;
    font-weight: 500; }
    @media (max-width: 499.98px) {
      .c-footer__developed {
        text-align: center;
        margin-top: 1rem; } }
  .c-footer__company {
    color: #0A0A0A;
    text-decoration: underline;
    transition: all 0.3s ease; }
  .c-footer .c-socials .icon {
    color: #0A0A0A; }
  .c-footer.dark {
    background-color: #0A0A0A; }
    .c-footer.dark .c-footer-list__txt {
      color: #7C7C7C; }
    .c-footer.dark .c-footer__title {
      color: white; }
    .c-footer.dark .c-footer__copy {
      color: #7C7C7C; }
    .c-footer.dark .c-footer__developed {
      color: #7C7C7C; }
    .c-footer.dark .c-footer__company {
      color: white; }
    .c-footer.dark .c-socials .icon {
      color: #7C7C7C; }

.c-header {
  padding: 4.3rem 0;
  position: relative;
  background-color: #EEEEEE;
  position: absolute;
  top: 0;
  right: 0;
  left: 0; }
  .c-header.fixed {
    padding: 1.9rem 0;
    z-index: 999;
    position: fixed; }
    .c-header.fixed .c-header__logo .icon-logo {
      width: 20rem;
      height: 2.5rem; }
      @media (max-width: 499.98px) {
        .c-header.fixed .c-header__logo .icon-logo {
          width: 10rem; } }
    @media (max-width: 499.98px) {
      .c-header.fixed {
        padding: 1rem 0; } }
  @media (max-width: 1199.98px) {
    .c-header {
      padding: 2rem 0; } }
  @media (max-width: 499.98px) {
    .c-header {
      padding: 1rem 0; } }
  .c-header__inner {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .c-header__actions {
    display: flex;
    align-items: center; }
  .c-header__logo .icon-logo {
    width: 20rem;
    height: 4rem; }
    @media (max-width: 499.98px) {
      .c-header__logo .icon-logo {
        width: 12rem; } }
  @media (max-width: 1199.98px) {
    .c-header-nav {
      display: none; } }
  .c-header-nav__list {
    display: flex; }
  .c-header-nav-item {
    margin-right: 3.5rem; }
    .c-header-nav-item__link {
      display: flex;
      align-items: center;
      transition: all 0.2s ease; }
      .c-header-nav-item__link .icon {
        font-size: 2.6rem; }
      .c-header-nav-item__link .icon-truck-tow {
        font-size: 3.6rem; }
    .c-header-nav-item__txt {
      margin-left: 1.2rem;
      max-width: 8.5rem;
      line-height: 1.6rem; }
  .c-header-phone {
    display: flex;
    align-items: center;
    margin-right: 3.2rem;
    transition: all 0.2s ease; }
    @media (max-width: 1199.98px) {
      .c-header-phone {
        display: none; } }
    .c-header-phone .icon-phone {
      font-size: 3rem; }
    .c-header-phone__info {
      display: flex;
      flex-direction: column;
      margin-left: 1rem; }
    .c-header-phone__txt {
      line-height: 2rem;
      font-feature-settings: "pnum" on, "lnum" on; }
  .c-header__language {
    display: flex;
    align-items: center;
    margin-right: 4.5rem; }
    @media (max-width: 499.98px) {
      .c-header__language {
        margin-right: 3rem; } }
  .c-header-menu-btn {
    display: flex;
    align-items: center;
    width: 4rem;
    height: 1.8rem;
    display: flex;
    align-items: center; }
    @media (max-width: 499.98px) {
      .c-header-menu-btn {
        width: 3rem; } }
    .c-header-menu-btn:hover .c-header-menu-btn__line {
      background-color: #EC6B41; }
      .c-header-menu-btn:hover .c-header-menu-btn__line:after, .c-header-menu-btn:hover .c-header-menu-btn__line:before {
        background-color: #EC6B41; }
    .c-header-menu-btn__line {
      background-color: #0A0A0A;
      position: relative;
      width: 100%;
      height: 0.2rem;
      display: inline-flex;
      transition: all 0.2s ease; }
      .c-header-menu-btn__line:after, .c-header-menu-btn__line:before {
        transition: all 0.2s ease;
        content: "";
        position: absolute;
        background-color: #0A0A0A;
        height: 0.2rem;
        width: 100%;
        left: 0;
        right: 0; }
      .c-header-menu-btn__line:before {
        top: -0.6rem; }
      .c-header-menu-btn__line:after {
        bottom: -0.6rem; }
  .c-header.above-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: transparent; }
    .c-header.above-content .c-header-nav-item__link,
    .c-header.above-content .c-header-phone,
    .c-header.above-content .c-header__language .dropdown-toggle {
      color: white; }
    .c-header.above-content .c-header-menu-btn__line,
    .c-header.above-content .c-header-menu-btn__line:after,
    .c-header.above-content .c-header-menu-btn__line:before {
      background-color: white; }
    .c-header.above-content .c-header__inner {
      z-index: 10;
      position: relative; }
    .c-header.above-content.fixed {
      background-color: #EEEEEE;
      padding: 1.9rem 0;
      position: fixed;
      z-index: 999; }
      .c-header.above-content.fixed .c-header-nav-item__link,
      .c-header.above-content.fixed .c-header-phone,
      .c-header.above-content.fixed .c-header__language .dropdown-toggle {
        color: #0A0A0A; }
      .c-header.above-content.fixed .c-header-menu-btn__line,
      .c-header.above-content.fixed .c-header-menu-btn__line:after,
      .c-header.above-content.fixed .c-header-menu-btn__line:before {
        background-color: #0A0A0A; }
      .c-header.above-content.fixed .c-header__logo .icon-logo {
        width: 20rem;
        height: 2.5rem; }
        @media (max-width: 499.98px) {
          .c-header.above-content.fixed .c-header__logo .icon-logo {
            width: 10rem; } }
      @media (max-width: 499.98px) {
        .c-header.above-content.fixed {
          padding: 1rem 0; } }
  .c-header-menu {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #0A0A0A;
    height: 100vh;
    z-index: 999;
    display: flex;
    flex-direction: column;
    padding: 5rem 13.5rem;
    transform: translateX(100%);
    transition: all 0.4s ease-in-out;
    opacity: 0;
    width: 50%; }
    @media (max-width: 1399.98px) {
      .c-header-menu {
        padding: 5rem; } }
    @media (max-width: 1199.98px) {
      .c-header-menu {
        width: 100vw; } }
    @media (max-width: 499.98px) {
      .c-header-menu {
        padding: 2rem; } }
    .c-header-menu.show {
      opacity: 1;
      transform: translateX(0); }
    .c-header-menu__list {
      counter-reset: counter; }
    .c-header-menu__close {
      position: absolute;
      right: 4rem;
      top: 5rem; }
      @media (max-width: 499.98px) {
        .c-header-menu__close {
          top: 2rem; } }
      .c-header-menu__close:hover .icon-close {
        color: #EC6B41; }
      .c-header-menu__close .icon-close {
        transition: all 0.3s ease;
        color: white; }
    .c-header-menu__wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex-grow: 1; }
    .c-header-menu__link {
      font-weight: 500;
      color: white;
      font-size: 4rem;
      line-height: 5.6rem; }
      @media (max-width: 719.98px) {
        .c-header-menu__link {
          font-size: 3rem; } }
      @media (max-width: 499.98px) {
        .c-header-menu__link {
          font-size: 2rem;
          line-height: normal; } }
    .c-header-menu__nav {
      flex-grow: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 5rem 0; }
      @media (max-width: 499.98px) {
        .c-header-menu__nav {
          padding: 2rem 0; } }
    .c-header-menu__item {
      position: relative;
      display: flex;
      align-items: center; }
      .c-header-menu__item:before {
        counter-increment: counter;
        content: "0" counters(counter, ".");
        font-weight: bold;
        font-size: 4.2rem;
        font-feature-settings: "pnum" on, "lnum" on;
        color: #7C7C7C;
        margin-right: 3.4rem;
        transition: all 0.3s ease; }
        @media (max-width: 719.98px) {
          .c-header-menu__item:before {
            font-size: 3.2rem; } }
        @media (max-width: 499.98px) {
          .c-header-menu__item:before {
            font-size: 2.4rem;
            line-height: normal; } }
      .c-header-menu__item:not(:last-child) {
        margin-bottom: 2rem; }
      .c-header-menu__item.active:before {
        color: #EC6B41; }
      .c-header-menu__item.active .c-header-menu__link {
        color: #EC6B41; }
      .c-header-menu__item:hover:before {
        color: #EC6B41; }
    .c-header-menu__link {
      transition: all 0.3s ease; }

.c-main-banner {
  background-image: url(../images/useful/main-banner/main-banner-2.jpg);
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  padding: 14rem 0 8rem;
  position: relative; }
  @media (max-width: 1199.98px) {
    .c-main-banner {
      padding: 12rem 0 8rem;
      background-image: url(../images/useful/main-banner/main-banner-2--md.jpg); } }
  @media (max-width: 991.98px) {
    .c-main-banner {
      padding: 10rem 0 5rem; } }
  @media (max-width: 719.98px) {
    .c-main-banner {
      background-image: url(../images/useful/main-banner/main-banner-2--xx.jpg); } }
  @media (max-width: 499.98px) {
    .c-main-banner {
      padding: 8rem 0 3rem; } }
  .c-main-banner__track {
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(-90deg);
    right: -11.8rem;
    background-color: #EC6B41;
    padding: 1.85rem 3.4rem; }
    @media (max-width: 719.98px) {
      .c-main-banner__track {
        display: none; } }
  .c-main-banner__wrapper {
    display: flex;
    flex-direction: column;
    max-width: 50rem;
    padding-top: 6rem;
    width: 100%; }
    @media (max-width: 719.98px) {
      .c-main-banner__wrapper {
        max-width: 100%; } }
  .c-main-banner__ico {
    font-size: 10.9rem; }
    @media (max-width: 1199.98px) {
      .c-main-banner__ico {
        font-size: 8rem; } }
    @media (max-width: 499.98px) {
      .c-main-banner__ico {
        font-size: 6rem; } }
  .c-main-banner__info {
    display: flex;
    margin-top: 3.4rem; }
    @media (max-width: 499.98px) {
      .c-main-banner__info {
        margin-top: 2.5rem; } }
  .c-main-banner__subtitle {
    color: white;
    font-weight: 500;
    text-transform: uppercase;
    margin: 0;
    letter-spacing: -.2px; }
    @media (max-width: 499.98px) {
      .c-main-banner__subtitle {
        font-size: 3rem; } }
  .c-main-banner__description {
    font-weight: 500;
    line-height: 2rem;
    color: white;
    max-width: 33rem;
    display: inline-flex;
    margin-top: 3rem;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 1.4rem;
    letter-spacing: -.1px; }
    @media (max-width: 719.98px) {
      .c-main-banner__description {
        max-width: 100%; } }
  .c-main-banner__img {
    margin-left: 1.2rem;
    margin-top: -5.5rem;
    position: relative;
    z-index: 2; }
    @media (max-width: 719.98px) {
      .c-main-banner__img {
        display: none; } }
  .c-main-banner__actions {
    margin-top: 2.4rem; }
    @media (max-width: 719.98px) {
      .c-main-banner__actions {
        display: flex;
        flex-direction: column; } }
    .c-main-banner__actions .btn:not(:last-child) {
      margin-right: 3rem; }
      @media (max-width: 719.98px) {
        .c-main-banner__actions .btn:not(:last-child) {
          margin-right: 0;
          margin-bottom: 2rem; } }
  .c-main-banner-video {
    display: flex;
    align-items: center;
    margin-top: 7.2rem;
    margin-left: 1.5rem; }
    .c-main-banner-video:hover .c-main-banner-video__ico:after, .c-main-banner-video:hover .c-main-banner-video__ico:before {
      animation-play-state: paused; }
    .c-main-banner-video__ico {
      height: 5.2rem;
      width: 5.2rem;
      border-radius: 50%;
      background-color: #EC6B41;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative; }
      .c-main-banner-video__ico .icon {
        position: relative;
        z-index: 5; }
      .c-main-banner-video__ico:after {
        content: "";
        position: absolute;
        height: 6.4rem;
        width: 6.4rem;
        border-radius: 50%;
        background-color: rgba(236, 107, 65, 0.5);
        animation: playWawe 1.5s infinite 1s;
        transition: all 0.3s ease; }
      .c-main-banner-video__ico:before {
        content: "";
        position: absolute;
        height: 7.6rem;
        width: 7.6rem;
        border-radius: 50%;
        background-color: rgba(236, 107, 65, 0.2);
        animation: playWawe 2s infinite 0.5s;
        transition: all 0.3s ease; }
    .c-main-banner-video__txt {
      margin-left: 2.2rem;
      font-weight: bold;
      font-size: 1.8rem;
      letter-spacing: 0.02em;
      color: #EC6B41; }
  .c-main-banner__advantages {
    margin-top: 9.4rem; }
    @media (max-width: 1199.98px) {
      .c-main-banner__advantages {
        margin-top: 5rem; } }
  .c-main-banner-advantage {
    display: flex;
    flex-direction: column;
    max-width: 33rem; }
    @media (max-width: 1199.98px) {
      .c-main-banner-advantage {
        max-width: 100%;
        margin-bottom: 2rem; } }
    @media (max-width: 991.98px) {
      .c-main-banner-advantage {
        display: none; } }
    .c-main-banner-advantage__title {
      font-weight: 900;
      line-height: 3.4rem;
      color: white; }
      @media (max-width: 1199.98px) {
        .c-main-banner-advantage__title {
          font-size: 2rem;
          line-height: normal;
          text-align: center; } }
    .c-main-banner-advantage__info {
      margin-top: .4rem;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: white;
      letter-spacing: -.4px; }
      @media (max-width: 1199.98px) {
        .c-main-banner-advantage__info {
          font-size: 1.4rem;
          line-height: 2rem;
          text-align: center; } }

.c-job-aid {
  padding-bottom: 9rem;
  position: relative; }
  .c-job-aid__wrapper {
    background-color: white;
    z-index: 100;
    position: relative; }
  .c-job-aid__inner {
    margin-top: 7rem;
    position: relative; }
    @media (max-width: 499.98px) {
      .c-job-aid__inner {
        margin-top: 4rem; } }
    .c-job-aid__inner:after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 2px;
      background-color: #0A0A0A;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1; }
      @media (max-width: 1199.98px) {
        .c-job-aid__inner:after {
          left: 19.8%;
          transform: none; } }
      @media (max-width: 991.98px) {
        .c-job-aid__inner:after {
          left: 28%; } }
      @media (max-width: 719.98px) {
        .c-job-aid__inner:after {
          left: 4rem; } }
      @media (max-width: 499.98px) {
        .c-job-aid__inner:after {
          left: 1rem; } }
  .c-job-aid-item {
    display: flex;
    cursor: pointer; }
    .c-job-aid-item:not(:last-child) {
      padding-bottom: 13rem; }
      @media (max-width: 1199.98px) {
        .c-job-aid-item:not(:last-child) {
          padding-bottom: 10rem; } }
      @media (max-width: 991.98px) {
        .c-job-aid-item:not(:last-child) {
          padding-bottom: 7rem; } }
    .c-job-aid-item:first-child .c-job-aid-item__img {
      margin-top: -12rem; }
    .c-job-aid-item__img {
      flex-shrink: 0;
      max-width: 39rem;
      width: 100%;
      display: flex;
      justify-content: center;
      opacity: 0.1;
      margin-top: -5.5rem;
      transition: all 0.3s ease; }
      @media (max-width: 1199.98px) {
        .c-job-aid-item__img {
          display: none; } }
    .c-job-aid-item__counter {
      margin-left: 3rem;
      position: relative;
      max-width: 11.6rem;
      width: 100%; }
      @media (max-width: 719.98px) {
        .c-job-aid-item__counter {
          margin-left: 6rem; } }
      @media (max-width: 499.98px) {
        .c-job-aid-item__counter {
          margin-left: 3rem; } }
    .c-job-aid-item__digit {
      font-size: 9.6rem;
      line-height: 6rem;
      font-weight: bold;
      letter-spacing: 0.02em;
      color: #0A0A0A;
      opacity: 0.3;
      position: relative;
      transform: translateY(1rem);
      transition: transform 1.5s ease; }
      @media (max-width: 719.98px) {
        .c-job-aid-item__digit {
          font-size: 6rem; } }
      @media (max-width: 499.98px) {
        .c-job-aid-item__digit {
          font-size: 4rem; } }
      .c-job-aid-item__digit:after {
        content: '';
        display: inline-block;
        position: absolute;
        right: -5.8rem;
        top: 50%;
        transform: translateY(-50%);
        height: 3rem;
        width: 3rem;
        border-radius: 50%;
        background-color: #EC6B41;
        z-index: 5;
        opacity: 0;
        visibility: hidden; }
        @media (max-width: 1399.98px) {
          .c-job-aid-item__digit:after {
            right: -4.9rem; } }
        @media (max-width: 1199.98px) {
          .c-job-aid-item__digit:after {
            right: -5.2rem; } }
        @media (max-width: 719.98px) {
          .c-job-aid-item__digit:after {
            left: -3rem;
            height: 2rem;
            width: 2rem; } }
    .c-job-aid-item__desc {
      max-width: 46rem;
      width: 100%;
      margin-left: 12.6rem;
      transform: translateX(1rem);
      transition: transform 1.5s ease; }
      @media (max-width: 1199.98px) {
        .c-job-aid-item__desc {
          max-width: 100%;
          margin-left: 10rem; } }
      @media (max-width: 719.98px) {
        .c-job-aid-item__desc {
          margin-left: 2rem; } }
      @media (max-width: 499.98px) {
        .c-job-aid-item__desc {
          display: flex;
          width: auto; } }
    .c-job-aid-item__info {
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 2.7rem;
      color: #0A0A0A;
      opacity: 0.3;
      transition: transform 0.3s ease;
      margin-bottom: 0; }
      @media (max-width: 991.98px) {
        .c-job-aid-item__info {
          font-size: 1.6rem;
          line-height: 2.2rem; } }
      @media (max-width: 499.98px) {
        .c-job-aid-item__info {
          font-size: 1.4rem;
          line-height: 2rem; } }
    .c-job-aid-item.active .c-job-aid-item__img {
      opacity: 1; }
    .c-job-aid-item.active .c-job-aid-item__info {
      opacity: 1; }
    .c-job-aid-item.active .c-job-aid-item__digit {
      color: #EC6B41;
      opacity: 1;
      transform: translateY(0); }
    .c-job-aid-item.active .c-job-aid-item__desc {
      transform: translateX(0); }
    .c-job-aid-item.active .c-job-aid-item__digit:after {
      opacity: 1;
      visibility: visible; }

.c-advantages {
  background-color: #0A0A0A;
  padding-bottom: 11rem; }
  .c-advantages__inner {
    position: relative;
    margin-top: 3rem;
    margin-bottom: -7.5rem; }
    .c-advantages__inner:before {
      content: attr(data-text);
      position: absolute;
      top: 0;
      right: -34rem;
      font-size: 14.4rem;
      color: white;
      opacity: 0.07;
      font-weight: 900;
      text-transform: uppercase;
      line-height: normal;
      letter-spacing: -1.2px; }
      @media (max-width: 1199.98px) {
        .c-advantages__inner:before {
          font-size: 10rem;
          opacity: 0.03; } }
    .c-advantages__inner:after {
      content: attr(data-text);
      position: absolute;
      bottom: 10.4rem;
      left: -40rem;
      font-size: 14.4rem;
      color: white;
      opacity: 0.07;
      font-weight: 900;
      text-transform: uppercase;
      line-height: normal; }
      @media (max-width: 1199.98px) {
        .c-advantages__inner:after {
          font-size: 10rem;
          opacity: 0.03; } }
  .c-advantages-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 7.5rem; }
    @media (max-width: 1199.98px) {
      .c-advantages-item {
        align-items: center;
        padding: 0 1rem; } }
    @media (max-width: 719.98px) {
      .c-advantages-item {
        flex-direction: row;
        align-items: flex-start;
        margin-bottom: 3rem; } }
    .c-advantages-item__ico {
      font-size: 6rem; }
      @media (max-width: 719.98px) {
        .c-advantages-item__ico {
          font-size: 3rem;
          margin-right: 2rem; } }
    .c-advantages-item__desc {
      margin-top: 1.2rem;
      margin-bottom: 0;
      color: white;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2.4rem;
      letter-spacing: -.2px; }
      @media (max-width: 719.98px) {
        .c-advantages-item__desc {
          font-size: 1.4rem;
          line-height: 2rem;
          margin-top: 0; } }

.c-clients {
  padding-bottom: 10rem; }
  .c-clients__inner {
    display: flex;
    flex-wrap: wrap;
    margin-top: 8.5rem;
    margin-bottom: -6rem; }
    @media (max-width: 991.98px) {
      .c-clients__inner {
        margin-top: 4rem; } }
    @media (max-width: 499.98px) {
      .c-clients__inner {
        margin-top: 2rem; } }
  .c-clients-item {
    width: 33.3333%;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-bottom: 6rem;
    transition: all 0.3s ease;
    max-height: 16rem; }
    @media (max-width: 991.98px) {
      .c-clients-item {
        width: 50%;
        margin-bottom: 3rem; } }
    @media (max-width: 499.98px) {
      .c-clients-item {
        width: 50%;
        margin-bottom: 1.5rem; } }
    .c-clients-item:hover {
      filter: grayscale(100%); }
    @media (max-width: 499.98px) {
      .c-clients-item__link {
        padding: 2rem; } }

.c-track-order {
  background-color: #0A0A0A;
  padding-bottom: 16.7rem; }
  @media (max-width: 991.98px) {
    .c-track-order {
      padding-bottom: 8rem; } }
  .c-track-order__inner {
    display: flex;
    margin-top: 8rem;
    position: relative;
    justify-content: center; }
    @media (max-width: 991.98px) {
      .c-track-order__inner {
        flex-direction: column;
        margin-top: 6rem; } }
    .c-track-order__inner:before {
      content: attr(data-text);
      position: absolute;
      top: 26px;
      right: -25rem;
      font-size: 14.4rem;
      color: white;
      opacity: 0.07;
      font-weight: 900;
      line-height: normal;
      z-index: 0;
      text-transform: uppercase; }
      @media (max-width: 1199.98px) {
        .c-track-order__inner:before {
          font-size: 10rem;
          opacity: 0.03; } }
    .c-track-order__inner:after {
      content: attr(data-text);
      position: absolute;
      bottom: -17px;
      left: -37rem;
      font-size: 14.4rem;
      color: white;
      opacity: 0.07;
      font-weight: 900;
      line-height: normal;
      text-transform: uppercase; }
      @media (max-width: 1199.98px) {
        .c-track-order__inner:after {
          font-size: 10rem;
          opacity: 0.03; } }
  @media (max-width: 991.98px) {
    .c-track-order__col {
      margin-bottom: 5rem; } }
  .c-track-order__title {
    max-width: 21rem;
    color: #EC6B41;
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 3.6rem;
    margin-bottom: 2.5rem;
    display: inline-block;
    margin-top: 7rem; }
    @media (max-width: 991.98px) {
      .c-track-order__title {
        max-width: 100%;
        display: block;
        text-align: center;
        margin-top: 0; } }
  .c-track-order__desc {
    max-width: 35rem;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: white;
    margin-bottom: 2.5rem; }
    @media (max-width: 991.98px) {
      .c-track-order__desc {
        max-width: 100%;
        text-align: center; } }
  .c-track-order__img {
    margin: 0 8rem;
    position: relative;
    z-index: 2; }
    @media (max-width: 1199.98px) {
      .c-track-order__img {
        margin: 0 4rem; } }
    @media (max-width: 991.98px) {
      .c-track-order__img {
        display: flex;
        justify-content: center; } }
  .c-track-order__col:first-child {
    display: flex;
    flex-direction: column;
    align-items: flex-end; }
    @media (max-width: 991.98px) {
      .c-track-order__col:first-child {
        align-items: flex-start; } }
    .c-track-order__col:first-child .c-track-order__title, .c-track-order__col:first-child .c-track-order__desc {
      text-align: right;
      margin-left: auto; }
      @media (max-width: 991.98px) {
        .c-track-order__col:first-child .c-track-order__title, .c-track-order__col:first-child .c-track-order__desc {
          text-align: center;
          width: 100%;
          margin-left: 0; } }

@media (max-width: 719.98px) {
  .c-feedback {
    padding-top: 3rem; } }

.c-feedback__inner {
  display: flex; }
  @media (max-width: 991.98px) {
    .c-feedback__inner {
      flex-direction: column; } }

.c-feedback__map {
  height: 62rem;
  width: 50%;
  flex: 0 0 50%;
  transform: translateX(-100%); }
  @media (max-width: 991.98px) {
    .c-feedback__map {
      width: 100%;
      transform: none;
      flex: none;
      height: 40rem;
      margin-top: 5rem; } }

.c-feedback__content {
  flex: 0 0 100%; }

.c-feedback__form {
  margin-top: 9.3rem;
  max-width: 43.6rem; }
  @media (max-width: 991.98px) {
    .c-feedback__form {
      max-width: 100%;
      margin-top: 3rem; } }
  .c-feedback__form .form-line {
    display: flex;
    margin-bottom: 2.7rem; }
    .c-feedback__form .form-line .e-input:not(:last-child) {
      margin-right: 4rem; }
    .c-feedback__form .form-line .half-width {
      width: 19.8rem; }
      @media (max-width: 991.98px) {
        .c-feedback__form .form-line .half-width {
          width: 47%; } }
      @media (max-width: 719.98px) {
        .c-feedback__form .form-line .half-width {
          width: 100%; } }
  .c-feedback__form .btn {
    margin-top: 0.7rem;
    padding: 1.85rem 5.6rem; }

.c-feedback.wide {
  padding-bottom: 10rem; }
  @media (max-width: 991.98px) {
    .c-feedback.wide .form-actions {
      flex-direction: column; } }
  .c-feedback.wide .e-section-title {
    font-size: 3rem; }
  .c-feedback.wide .c-feedback__form {
    max-width: 100%;
    margin-top: 5rem; }
    .c-feedback.wide .c-feedback__form .form-actions {
      display: flex; }
  .c-feedback.wide .form-group:not(:last-child) {
    margin-right: 4rem;
    max-width: 19.8rem; }
    @media (max-width: 991.98px) {
      .c-feedback.wide .form-group:not(:last-child) {
        max-width: 100%;
        margin-bottom: 3rem;
        margin-right: 0; } }
  @media (max-width: 991.98px) {
    .c-feedback.wide .form-group .e-input {
      margin-bottom: 2rem;
      max-width: 100%;
      margin-bottom: 3rem;
      margin-right: 0; } }

.c-feedback .c-feedback__form .btn {
  margin-top: 4.4rem; }

.c-modal-comment .modal-dialog {
  max-width: 75.2rem; }

.c-modal-comment__inner {
  padding: 3rem 8rem 2rem 6.6rem;
  display: flex;
  align-items: center; }
  @media (max-width: 499.98px) {
    .c-modal-comment__inner {
      padding: 3rem 6rem 3rem 3rem; } }

.c-modal-comment__img {
  margin-right: 4.7rem; }
  @media (max-width: 719.98px) {
    .c-modal-comment__img {
      display: none; } }

.c-modal-comment__title {
  color: #EC6B41;
  font-weight: 900;
  font-size: 4rem;
  text-transform: uppercase;
  max-width: 33rem; }
  @media (max-width: 499.98px) {
    .c-modal-comment__title {
      font-size: 2rem;
      line-height: 3rem; } }

.c-modal-video__frame {
  position: relative;
  cursor: pointer; }
  .c-modal-video__frame:after {
    content: '';
    display: block;
    padding-bottom: 56.5%; }
  .c-modal-video__frame img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    border: 0; }
  .c-modal-video__frame iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    border: 0; }

.c-modal-video .close {
  top: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 1rem; }
  .c-modal-video .close .icon {
    color: black; }

.c-404 {
  padding: 14rem 0 7rem; }
  @media (max-width: 719.98px) {
    .c-404 {
      padding: 4rem 0; } }
  .c-404__inner {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative; }
    .c-404__inner:before {
      content: attr(data-text);
      position: absolute;
      top: -7rem;
      left: 36rem;
      font-size: 14.4rem;
      color: #7C7C7C;
      opacity: 0.07;
      font-weight: 900;
      text-transform: uppercase;
      line-height: normal;
      letter-spacing: -1.2px;
      white-space: nowrap; }
      @media (max-width: 1199.98px) {
        .c-404__inner:before {
          font-size: 10rem;
          opacity: 0.03; } }
    .c-404__inner:after {
      content: attr(data-text);
      position: absolute;
      bottom: 1rem;
      left: -99rem;
      font-size: 14.4rem;
      color: #7C7C7C;
      opacity: 0.07;
      font-weight: 900;
      text-transform: uppercase;
      line-height: normal;
      white-space: nowrap; }
      @media (max-width: 1199.98px) {
        .c-404__inner:after {
          font-size: 10rem;
          opacity: 0.03; } }
    .c-404__inner img {
      position: relative; }
    .c-404__inner .btn {
      margin-top: 10rem;
      padding: 1.85rem 7.9rem;
      position: relative;
      z-index: 5; }

.c-breadcrumbs {
  padding: 4rem 0; }
  @media (max-width: 499.98px) {
    .c-breadcrumbs {
      padding: 2rem 0; } }
  .c-breadcrumbs__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
  .c-breadcrumbs__item {
    color: #919191; }
    @media (max-width: 499.98px) {
      .c-breadcrumbs__item {
        margin-bottom: .5rem; } }
    .c-breadcrumbs__item:not(:last-child) {
      margin-right: 1rem; }
    .c-breadcrumbs__item:hover .c-breadcrumbs__link, .c-breadcrumbs__item:hover .icon {
      color: #EC6B41; }
  .c-breadcrumbs__link {
    color: #D2D2D2;
    position: relative;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
    font-weight: bold;
    font-size: 1.8rem; }
    @media (max-width: 499.98px) {
      .c-breadcrumbs__link {
        font-size: 1.4rem; } }
    .c-breadcrumbs__link .icon {
      color: #D2D2D2;
      font-size: 1.6rem;
      margin-left: 1rem;
      transition: all 0.3s ease; }
  .c-breadcrumbs__txt {
    color: #7C7C7C;
    font-weight: bold;
    font-size: 1.8rem; }
    @media (max-width: 499.98px) {
      .c-breadcrumbs__txt {
        font-size: 1.4rem; } }

.c-price {
  padding-bottom: 4rem; }
  .c-price-item {
    border: 2px solid #D2D2D2;
    padding: 2rem 3rem 5.6rem;
    min-height: 45rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 6rem;
    position: relative;
    transition: all 0.3s ease; }
    @media (max-width: 991.98px) {
      .c-price-item {
        min-height: 40rem;
        margin-bottom: 2rem; } }
    @media (max-width: 719.98px) {
      .c-price-item {
        padding: 3rem;
        min-height: auto; } }
    @media (max-width: 499.98px) {
      .c-price-item {
        padding: 2rem; } }
    .c-price-item:hover {
      background-color: #EEEEEE;
      border: 2px solid #EEEEEE; }
      .c-price-item:hover .c-price-item__title {
        color: #0A0A0A; }
    .c-price-item__mark {
      display: flex;
      justify-content: flex-end; }
      .c-price-item__mark .icon {
        font-size: 6rem; }
        @media (max-width: 991.98px) {
          .c-price-item__mark .icon {
            font-size: 4rem; } }
    .c-price-item__content {
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: center; }
    .c-price-item__title {
      line-height: 3.4rem;
      text-align: center;
      margin-top: 6.4rem;
      color: #0A0A0A; }
      @media (max-width: 719.98px) {
        .c-price-item__title {
          margin-top: 3rem; } }
      @media (max-width: 499.98px) {
        .c-price-item__title {
          font-size: 2.4rem;
          line-height: normal; } }
    .c-price-item-link {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: auto; }
      @media (max-width: 719.98px) {
        .c-price-item-link {
          margin-top: 4rem; } }
      .c-price-item-link .icon {
        font-size: 5rem;
        margin-bottom: 1rem; }
        @media (max-width: 991.98px) {
          .c-price-item-link .icon {
            font-size: 3rem; } }
      .c-price-item-link__txt {
        font-size: 1.8rem;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 0.02em; }

.c-contacts__inner {
  display: flex; }
  @media (max-width: 991.98px) {
    .c-contacts__inner {
      flex-direction: column; } }

.c-contacts__content {
  flex: 0 0 100%; }
  @media (max-width: 991.98px) {
    .c-contacts__content {
      width: 100%; } }

.c-contacts__map {
  height: 62rem;
  width: 60%;
  flex: 0 0 60%;
  transform: translateX(-100%); }
  @media only screen and (min-width: 2000px) {
    .c-contacts__map {
      width: 55%;
      flex: 0 0 55%; } }
  @media (max-width: 991.98px) {
    .c-contacts__map {
      width: 100%;
      transform: none;
      flex: none;
      height: 40rem;
      margin-top: 5rem; } }

.c-contacts-item {
  display: flex;
  max-width: 29.3rem;
  align-items: flex-start; }
  .c-contacts-item:not(:last-child) {
    margin-bottom: 2.5rem; }
  .c-contacts-item__ico {
    display: flex;
    justify-content: center; }
    .c-contacts-item__ico .icon {
      color: #EC6B41;
      font-size: 3.5rem; }
  .c-contacts-item__info {
    display: flex;
    flex-direction: column;
    margin-left: 1.5rem; }
  .c-contacts-item__title {
    font-weight: 500;
    color: #EC6B41;
    font-size: 1.8rem;
    font-feature-settings: 'pnum' on, 'lnum' on; }
  .c-contacts-item__text {
    font-size: 1.8rem;
    font-feature-settings: 'pnum' on, 'lnum' on; }

.c-contacts__list {
  margin-top: 10rem; }

.c-portfolio-item {
  margin-bottom: 6rem; }
  @media (max-width: 499.98px) {
    .c-portfolio-item {
      margin-bottom: 2rem; } }
  .c-portfolio-item:hover {
    color: inherit; }
    .c-portfolio-item:hover .demo-gallery-poster {
      opacity: 1;
      visibility: visible; }
  .c-portfolio-item__img {
    position: relative;
    height: 37.7rem; }
    @media (max-width: 1199.98px) {
      .c-portfolio-item__img {
        height: 30rem; } }
    @media (max-width: 499.98px) {
      .c-portfolio-item__img {
        height: 20rem; } }
    .c-portfolio-item__img .demo-gallery-poster {
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center; }
      .c-portfolio-item__img .demo-gallery-poster .icon {
        font-size: 2.03rem; }
  .c-portfolio-item__info {
    display: flex;
    flex-direction: column;
    padding-top: 2rem; }
    @media (max-width: 499.98px) {
      .c-portfolio-item__info {
        mask-position: 1rem; } }
  .c-portfolio-item__title {
    font-size: 1.8rem;
    font-weight: bold; }
    @media (max-width: 499.98px) {
      .c-portfolio-item__title {
        font-size: 1.6rem; } }
  .c-portfolio-item__subtitle {
    font-weight: 300;
    margin-top: 1rem; }
    @media (max-width: 499.98px) {
      .c-portfolio-item__subtitle {
        margin-top: 0; } }

.c-pagination {
  display: flex;
  justify-content: center;
  padding-bottom: 6rem; }
  .c-pagination__list {
    display: flex; }
  .c-pagination__nav {
    display: flex;
    align-items: center; }
  .c-pagination__link {
    font-weight: 500;
    font-feature-settings: "pnum" on, "lnum" on;
    display: inline-flex;
    min-width: 4rem;
    padding: 0.8rem 1rem;
    height: 4rem;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    background-color: #fff; }
    @media (max-width: 499.98px) {
      .c-pagination__link {
        min-width: 3rem;
        padding: 0.8rem;
        height: 3rem; } }
  .c-pagination__item {
    margin-right: .6rem; }
    @media (max-width: 499.98px) {
      .c-pagination__item {
        margin-right: .3rem; } }
    .c-pagination__item:first-child {
      margin-left: .6rem; }
      @media (max-width: 499.98px) {
        .c-pagination__item:first-child {
          margin-left: .3rem; } }
    .c-pagination__item:hover .c-pagination__link {
      background-color: #EC6B41;
      color: white; }
    .c-pagination__item--disabled {
      pointer-events: none; }
    .c-pagination__item--active .c-pagination__link {
      background-color: #EC6B41;
      color: white; }
  .c-pagination__btn {
    min-width: 4rem;
    padding: 0.8rem 1rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (max-width: 499.98px) {
      .c-pagination__btn {
        min-width: 3rem;
        padding: 0.8rem;
        height: 3rem; } }
    .c-pagination__btn .icon {
      transition: all 0.3s ease-in-out;
      font-size: 1.4rem; }
    .c-pagination__btn:hover .icon {
      color: #EC6B41; }
    .c-pagination__btn--prev {
      transform: rotate(-180deg); }
    .c-pagination__btn--two:hover .icon {
      color: #EC6B41; }
    .c-pagination__btn--two--next {
      transform: rotate(-180deg); }

.c-info-head {
  margin-bottom: 6.2rem; }
  .c-info-head h2 {
    color: #0A0A0A;
    text-transform: uppercase;
    line-height: 5rem;
    max-width: 62rem;
    margin-bottom: 0; }
    @media (max-width: 991.98px) {
      .c-info-head h2 {
        font-size: 3rem;
        line-height: 4rem; } }
    @media (max-width: 499.98px) {
      .c-info-head h2 {
        font-size: 2.6rem;
        line-height: 3.2rem; } }
  .c-info-head p {
    margin-top: 4.3rem;
    font-size: 1.8rem;
    line-height: 2.9rem;
    font-weight: 500;
    max-width: 72.3rem; }
    @media (max-width: 991.98px) {
      .c-info-head p {
        font-size: 1.6rem; } }
    @media (max-width: 499.98px) {
      .c-info-head p {
        font-size: 1.4rem;
        line-height: 2.2rem; } }
  .c-info-head a {
    margin-top: 3.5rem;
    padding: 1.85rem 7.8rem; }
  .c-info-head__img {
    min-height: 25rem;
    max-height: 100%;
    height: 100%;
    position: relative; }
    @media (max-width: 1199.98px) {
      .c-info-head__img {
        display: none; } }
    .c-info-head__img img {
      width: auto;
      padding: 2rem 0; }

.c-info-table {
  margin-bottom: 6.4rem; }
  .c-info-table table {
    margin-top: 4.5rem;
    border: 2px solid #7C7C7C;
    width: 100%; }
    .c-info-table table thead tr {
      background-color: #EC6B41;
      border-bottom: 2px solid #7C7C7C; }
      .c-info-table table thead tr th {
        font-size: 1.8rem;
        font-weight: bold;
        letter-spacing: 0.02em;
        color: white;
        text-align: center;
        padding: 2.1rem;
        font-feature-settings: 'pnum' on, 'lnum' on;
        width: 33.33333333%; }
        @media (max-width: 499.98px) {
          .c-info-table table thead tr th {
            font-size: 1.4rem;
            font-weight: 600;
            padding: 0.6rem; } }
        .c-info-table table thead tr th:not(:last-child) {
          border-right: 2px solid #7C7C7C; }
    .c-info-table table tbody tr:not(:last-child) {
      border-bottom: 2px solid #7C7C7C; }
    .c-info-table table tbody tr td {
      text-align: center;
      font-weight: 500;
      letter-spacing: 0.02em;
      padding: .7rem;
      font-feature-settings: 'pnum' on, 'lnum' on;
      width: 33.33333333%; }
      @media (max-width: 499.98px) {
        .c-info-table table tbody tr td {
          font-size: 1.4rem; } }
      .c-info-table table tbody tr td:not(:last-child) {
        border-right: 2px solid #7C7C7C; }
  .c-info-table.two-thirds table {
    width: 66.66666%; }
    @media (max-width: 499.98px) {
      .c-info-table.two-thirds table {
        width: 100%; } }

.c-info-text {
  margin-bottom: 6rem; }
  @media (max-width: 499.98px) {
    .c-info-text {
      margin-bottom: 2.5rem; } }
  .c-info-text__head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem; }
    @media (max-width: 719.98px) {
      .c-info-text__head {
        flex-direction: column; } }
    .c-info-text__head h2 {
      margin-bottom: 0 !important; }
      @media (max-width: 719.98px) {
        .c-info-text__head h2 {
          margin-bottom: 2rem !important; } }
  .c-info-text h1, .c-info-text h2, .c-info-text h3, .c-info-text h4, .c-info-text h5, .c-info-text h6 {
    margin-bottom: 2rem; }
  .c-info-text h2 {
    margin-bottom: 4rem; }
  .c-info-text h3 {
    font-size: 2.8rem;
    margin-bottom: 4rem; }
  .c-info-text h4 {
    font-size: 2.4rem;
    margin-bottom: 1.2rem; }
  .c-info-text a, .c-info-text span, .c-info-text p, .c-info-text b {
    font-weight: 1.8rem;
    font-weight: 500;
    max-width: 78.2rem; }
    @media (max-width: 991.98px) {
      .c-info-text a, .c-info-text span, .c-info-text p, .c-info-text b {
        font-size: 1.6rem; } }
    @media (max-width: 499.98px) {
      .c-info-text a, .c-info-text span, .c-info-text p, .c-info-text b {
        font-size: 1.4rem; } }
  .c-info-text p, .c-info-text b {
    margin-bottom: 2.9rem;
    display: block; }
  .c-info-text a {
    color: #EC6B41;
    font-weight: bold; }
  .c-info-text p {
    margin-bottom: 4rem; }
    @media (max-width: 499.98px) {
      .c-info-text p {
        margin-bottom: 2.5rem; } }
  .c-info-text ul {
    padding-left: 1.6rem;
    margin: 0;
    margin-top: 1rem; }
    .c-info-text ul li {
      margin-bottom: .5rem; }
  .c-info-text img {
    margin-bottom: 6rem; }

.c-concrete-propose {
  padding-bottom: 5.5rem; }
  .c-concrete-propose__select {
    width: 34.3rem;
    margin-top: 4rem; }
  .c-concrete-propose__list {
    margin-top: 8rem;
    margin-bottom: -8rem; }
  .c-concrete-propose-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 8rem; }
    .c-concrete-propose-item__head {
      width: 20.4rem;
      height: 20.4rem;
      border-radius: 50%;
      border: 2px solid #EC6B41;
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      align-items: center;
      justify-content: center; }
      @media (max-width: 499.98px) {
        .c-concrete-propose-item__head {
          height: 15rem;
          width: 15rem; } }
      .c-concrete-propose-item__head .icon {
        font-size: 6rem;
        margin-bottom: 2rem; }
        @media (max-width: 499.98px) {
          .c-concrete-propose-item__head .icon {
            font-size: 4rem; } }
    .c-concrete-propose-item__title {
      font-feature-settings: 'pnum' on, 'lnum' on;
      font-size: 1.8rem;
      font-weight: bold;
      max-width: 8rem;
      text-align: center;
      line-height: 2.5rem; }
      @media (max-width: 499.98px) {
        .c-concrete-propose-item__title {
          font-size: 1.6rem; } }
    .c-concrete-propose-item__info {
      font-size: 1.8rem;
      font-weight: 500;
      text-align: center;
      line-height: 2.7rem;
      max-width: 34.5rem;
      display: inline-block;
      margin-top: 3.2rem; }
      @media (max-width: 991.98px) {
        .c-concrete-propose-item__info {
          font-size: 1.6rem; } }
      @media (max-width: 499.98px) {
        .c-concrete-propose-item__info {
          font-size: 1.4rem; } }

.c-rent__list {
  margin-top: 2rem; }

.c-rent-tile {
  border: 2px solid #EEEEEE;
  display: flex;
  flex-direction: column;
  padding: 0 1rem 3.6rem 1rem;
  margin-bottom: 6rem;
  height: auto;
  align-items: center;
  min-height: 45rem;
  transition: all 0.3s ease;
  overflow: hidden; }
  @media (max-width: 1199.98px) {
    .c-rent-tile {
      min-height: 40rem; } }
  .c-rent-tile:hover .c-rent-tile__img img {
    transform: scale(1.05); }
  .c-rent-tile__img {
    margin-bottom: 3rem;
    flex-grow: 1;
    align-items: center;
    display: flex;
    height: 30rem;
    width: 100%;
    justify-content: center; }
    .c-rent-tile__img img {
      transition: all 0.5s ease; }
  .c-rent-tile__title {
    margin-top: auto; }
  .c-rent-tile.card .c-rent-tile__title {
    font-weight: 500;
    max-width: 23.6rem;
    text-align: center;
    margin: auto; }

.c-fast-order {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 1rem; }
  @media (max-width: 991.98px) {
    .c-fast-order {
      margin: 2rem 0; } }
  @media (max-width: 719.98px) {
    .c-fast-order {
      align-items: center; } }
  .c-fast-order .icon {
    color: #EC6B41;
    font-size: 3rem;
    margin-right: 1.3rem; }
    @media (max-width: 719.98px) {
      .c-fast-order .icon {
        font-size: 2rem; } }
  .c-fast-order__link {
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease; }
  .c-fast-order__ttl {
    font-size: 2.4rem;
    line-height: normal;
    font-weight: 500;
    font-feature-settings: 'pnum' on, 'lnum' on; }
    @media (max-width: 719.98px) {
      .c-fast-order__ttl {
        font-size: 1.6rem; } }
  .c-fast-order__text {
    font-size: 1.2rem;
    font-weight: 500;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #7C7C7C; }

.c-card__head {
  display: flex;
  align-items: center;
  margin-top: 3rem; }
  @media (max-width: 991.98px) {
    .c-card__head {
      margin-top: 0; } }

.c-card__img {
  height: 38rem;
  flex-shrink: 0;
  margin-right: 6rem; }
  @media (max-width: 1199.98px) {
    .c-card__img {
      height: 30rem; } }
  @media (max-width: 991.98px) {
    .c-card__img {
      height: 36rem;
      margin: 2rem 0; } }
  @media (max-width: 499.98px) {
    .c-card__img {
      height: 25rem;
      margin: 2rem 0; } }

.c-card__info p {
  margin-bottom: 4rem; }
  @media (max-width: 499.98px) {
    .c-card__info p {
      font-size: 1.4rem;
      margin-bottom: 2rem; } }

.c-card-characteristics {
  margin: 6rem 0; }
  .c-card-characteristics__list {
    margin-top: 4rem;
    max-width: 55.6rem;
    width: 100%; }
  .c-card-characteristics__item {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .c-card-characteristics__item:not(:last-child) {
      margin-bottom: 1.8rem; }
      @media (max-width: 499.98px) {
        .c-card-characteristics__item:not(:last-child) {
          margin-bottom: 1rem; } }
  .c-card-characteristics__txt {
    font-weight: 500; }
    @media (max-width: 499.98px) {
      .c-card-characteristics__txt {
        font-size: 1.4rem; } }
    .c-card-characteristics__txt:first-child {
      margin-right: 3rem; }

.c-certificates {
  margin-bottom: 8rem; }
  @media (max-width: 719.98px) {
    .c-certificates {
      margin-bottom: 4rem; } }
  .c-certificates-item {
    position: relative; }
    @media (max-width: 719.98px) {
      .c-certificates-item {
        margin-bottom: 2rem; } }
    .c-certificates-item__inner {
      position: relative; }
      .c-certificates-item__inner img {
        width: 100%; }
    .c-certificates-item .demo-gallery-poster {
      position: absolute;
      top: 2rem;
      right: 3.5rem; }
      .c-certificates-item .demo-gallery-poster .icon {
        font-size: 2.4rem; }

.c-modal-order .modal-dialog {
  max-width: 75.2rem; }

.c-modal-order__img {
  position: absolute;
  bottom: 0;
  right: 3rem; }
  @media (max-width: 719.98px) {
    .c-modal-order__img {
      display: none; } }

.c-modal-order__content {
  padding: 9.5rem 14.5rem; }
  @media (max-width: 719.98px) {
    .c-modal-order__content {
      padding: 4rem; } }

.c-modal-order__title {
  text-transform: uppercase;
  color: white;
  text-align: center; }
  @media (max-width: 499.98px) {
    .c-modal-order__title {
      font-size: 2.4rem; } }

.c-modal-order__form {
  margin-top: 10rem; }
  @media (max-width: 991.98px) {
    .c-modal-order__form {
      max-width: 100%;
      margin-top: 3rem; } }
  .c-modal-order__form .form-line {
    display: flex;
    margin-bottom: 2.7rem; }
    @media (max-width: 499.98px) {
      .c-modal-order__form .form-line {
        flex-direction: column; } }
    .c-modal-order__form .form-line .e-input:not(:last-child) {
      margin-right: 4rem; }
      @media (max-width: 499.98px) {
        .c-modal-order__form .form-line .e-input:not(:last-child) {
          margin-right: 0;
          margin-bottom: 2.7rem; } }
    .c-modal-order__form .form-line .e-input .form-control {
      background-color: transparent;
      border-bottom: 2px solid white;
      color: white; }
      .c-modal-order__form .form-line .e-input .form-control::placeholder {
        color: white; }
    .c-modal-order__form .form-line .half-width {
      width: 19.8rem; }
      @media (max-width: 991.98px) {
        .c-modal-order__form .form-line .half-width {
          width: 47%; } }
      @media (max-width: 499.98px) {
        .c-modal-order__form .form-line .half-width {
          width: 100%; } }
  .c-modal-order__form .btn {
    margin-top: 0.7rem;
    padding: 1.85rem 5.6rem; }
    @media (max-width: 719.98px) {
      .c-modal-order__form .btn {
        width: 100%; } }

.c-socials {
  display: flex;
  align-items: center; }
  .c-socials__item:hover .icon {
    color: #EC6B41; }
  .c-socials__item:last-child .c-socials__link {
    padding-right: 0; }
  .c-socials__link {
    padding: 0 .8rem; }
    .c-socials__link .icon {
      color: white;
      font-size: 2.4rem;
      transition: all 0.3s ease; }
  .c-socials.d-end {
    display: flex;
    justify-content: flex-end; }
  .c-socials--footer {
    margin-top: 1rem; }
    @media (max-width: 499.98px) {
      .c-socials--footer {
        display: flex;
        justify-content: center; } }
    .c-socials--footer .icon {
      color: #7C7C7C; }
  .c-socials--menu {
    margin-top: 4rem; }

.e-section-title {
  color: #0A0A0A;
  text-transform: uppercase;
  line-height: 5rem;
  max-width: 62rem;
  margin-bottom: 0;
  font-size: 3.6rem; }
  @media (max-width: 991.98px) {
    .e-section-title {
      font-size: 3rem;
      line-height: 4rem; } }
  @media (max-width: 499.98px) {
    .e-section-title {
      font-size: 2.4rem;
      line-height: 3rem; } }
  .e-section-title.t-center {
    width: 100%;
    margin: 0 auto;
    text-align: center; }
  .e-section-title.pt100 {
    padding-top: 10rem; }
    @media (max-width: 991.98px) {
      .e-section-title.pt100 {
        padding-top: 8rem; } }
    @media (max-width: 719.98px) {
      .e-section-title.pt100 {
        padding-top: 6rem; } }
    @media (max-width: 499.98px) {
      .e-section-title.pt100 {
        padding-top: 4rem; } }
  .e-section-title.pt80 {
    padding-top: 8rem; }
    @media (max-width: 991.98px) {
      .e-section-title.pt80 {
        padding-top: 6rem; } }
    @media (max-width: 719.98px) {
      .e-section-title.pt80 {
        padding-top: 4rem; } }
    @media (max-width: 499.98px) {
      .e-section-title.pt80 {
        padding-top: 2rem; } }
  .e-section-title.pt40 {
    padding-top: 4rem; }
  .e-section-title.pb40 {
    padding-bottom: 4rem; }
    @media (max-width: 499.98px) {
      .e-section-title.pb40 {
        padding-bottom: 2rem; } }
  .e-section-title.accent {
    color: #EC6B41; }

.e-input {
  margin: 0;
  width: 100%; }
  .e-input .form-control {
    border: none;
    border-bottom: 2px solid #0A0A0A;
    padding: 0;
    font-size: 1.6rem;
    font-weight: 500;
    color: #0A0A0A;
    font-feature-settings: 'pnum' on, 'lnum' on; }
    .e-input .form-control::placeholder {
      font-size: 1.6rem;
      font-weight: 500;
      color: #0A0A0A; }
    .e-input .form-control:focus, .e-input .form-control:active {
      outline: none;
      box-shadow: none; }
