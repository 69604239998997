.c-advantages {
  background-color: $dark;
  padding-bottom: 11rem;
  &__inner {
    position: relative;
    margin-top: 3rem;
    margin-bottom: -7.5rem;
    &:before {
      content: attr(data-text);
      position: absolute;
      top: 0;
      right: -34rem;
      font-size: 14.4rem;
      color: white;
      opacity: 0.07;
      font-weight: 900;
      text-transform: uppercase;
      line-height: normal;
      letter-spacing: -1.2px;
      @include media-breakpoint-down(md) {
        font-size: 10rem;
        opacity: 0.03;
      }
    }
    &:after {
      content: attr(data-text);
      position: absolute;
      bottom: 10.4rem;
      left: -40rem;
      font-size: 14.4rem;
      color: white;
      opacity: 0.07;
      font-weight: 900;
      text-transform: uppercase;
      line-height: normal;
      @include media-breakpoint-down(md) {
        font-size: 10rem;
        opacity: 0.03;
      }
    }
  }
  &-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 7.5rem;
    @include media-breakpoint-down(md) {
      align-items: center;
      padding: 0 1rem;
    }
    @include media-breakpoint-down(xs) {
      flex-direction: row;
      align-items: flex-start;
      margin-bottom: 3rem;
    }
    &__ico {
      font-size: 6rem;
      @include media-breakpoint-down(xs) {
        font-size: 3rem;
        margin-right: 2rem;
      }
    }
    &__desc {
      margin-top: 1.2rem;
      margin-bottom: 0;
      color: white;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2.4rem;
      letter-spacing: -.2px;
      @include media-breakpoint-down(xs) {
        font-size: 1.4rem;
        line-height: 2rem;
        margin-top: 0;
      }
    }
  }
}