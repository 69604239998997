.c-footer{
	padding: 5.4rem 0 4rem;
	background-color: $light-gray;
	&__logo {
		margin-bottom: .8rem;
		display: inline-block;
		@include media-breakpoint-down(xx) {
			display: flex;
			justify-content: center;
		}
	}
	&-list{
		margin-top: 2.3rem;
		display: flex;
		flex-direction: column;
		@include media-breakpoint-down(xx) {
			margin-top: 1rem;
			align-items: center;
		}
		&__item {
			margin-bottom: 1.8rem;
			@include media-breakpoint-down(sm) {
				margin-bottom: 1rem;
			}
			@include media-breakpoint-down(xx) {
				margin-bottom: 1rem;
			}
		}
		&__txt {
			font-size: 1.4rem;
			font-feature-settings: 'pnum' on, 'lnum' on;
			color: $dark;
			font-weight: 600;
			display: flex;
			transition: all 0.3s ease;
		}
	}
	&__title {
		font-weight: 900;
		font-size: 1.6rem;
		line-height: 2.4rem;
		color: $dark;
		@include media-breakpoint-down(xx) {
			text-align: center;
		}
	}
	&__category {
		display: flex;
		flex-direction: column;
		padding-top: .5rem;
		@include media-breakpoint-down(sm) {
			margin-bottom: 1rem;
			margin-top: 1rem;
		}
	}
	&__main-info {
		@include media-breakpoint-down(sm) {
			margin-bottom: 2rem;
		}
	}
	.row > *:last-child > .c-footer__category{
		margin-left: auto;
		text-align: right;
		@include media-breakpoint-down(sm) {
			margin-left: 0;
			text-align: left;
    }
		.c-footer-list {
			align-items: flex-end;
			@include media-breakpoint-down(sm) {
				align-items: flex-start;
			}
			@include media-breakpoint-down(xx) {
				align-items: center;
			}
		}
	}
	&__main {
		padding-bottom: 1rem;
	}
	&__bottom {
		padding: 4rem 0 0;
		display: flex;
		justify-content: space-between;
		@include media-breakpoint-down(xx) {
			flex-direction: column;
		}
	}
	&__copy {
		font-size: 1.4rem;
		font-feature-settings: 'pnum' on, 'lnum' on;
		color: $dark;
		font-weight: 500;
		@include media-breakpoint-down(xx) {
			text-align: center;
		}
	}
	&__developed {
		font-size: 1.4rem;
		font-feature-settings: 'pnum' on, 'lnum' on;
		color: $dark;
		font-weight: 500;
		@include media-breakpoint-down(xx) {
			text-align: center;
			margin-top: 1rem;
		}
	}
	&__company {
		color: $dark;
		text-decoration: underline;
		transition: all 0.3s ease;
	}
	.c-socials {
		.icon {
			color: $dark;
		}
	}
	&.dark {
		background-color: $dark;
		.c-footer {
			&-list{
				&__txt {
					color: $dark-gray;
				}
			}
			&__title {
				color: white;
			}
			&__copy {
				color: $dark-gray;
			}
			&__developed {
				color: $dark-gray;
			}
			&__company {
				color: white;
			}
		}
		.c-socials {
			.icon {
				color: $dark-gray;
			}
		}
	}
}