.c-fast-order {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 1rem;
  @include media-breakpoint-down(sm) {
    margin: 2rem 0;
  }
  @include media-breakpoint-down(xs) {
    align-items: center;
  }
  .icon {
    color: $accent;
    font-size: 3rem;
    margin-right: 1.3rem;
    @include media-breakpoint-down(xs) {
      font-size: 2rem;
    }
  }
  &__link {
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease;
  }
  &__ttl {
    font-size: 2.4rem;
    line-height: normal;
    font-weight: 500;
    font-feature-settings: 'pnum' on, 'lnum' on;
    @include media-breakpoint-down(xs) {
      font-size: 1.6rem;
    }
  }
  &__text {
    font-size: 1.2rem;
    font-weight: 500;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: $dark-gray;
  }
}