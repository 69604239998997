.c-modal-video {
  &__frame {
    position: relative;
    cursor: pointer;
    &:after {
      content: '';
      display: block;
      padding-bottom: 56.5%;
    }
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 100%;
      width: 100%;
      border: 0;
    }
    iframe {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 100%;
      width: 100%;
      border: 0;
    }
  }
  .close {
    top: 0;
    right: 0;
    background-color:  rgba($color: #fff, $alpha: 0.8);
    padding: 1rem;
    .icon {
      color: black;
    }
  }
}
