.c-rent {
  &__list {
    margin-top: 2rem;
  }
  &-tile {
    border: 2px solid #EEEEEE;
    display: flex;
    flex-direction: column;
    padding: 0 1rem 3.6rem 1rem;
    margin-bottom: 6rem;
    height: auto;
    align-items: center;
    min-height: 45rem;
    transition: all 0.3s ease;
    overflow: hidden;
    @include media-breakpoint-down(md) {
			min-height: 40rem;
		}
    &:hover {
      .c-rent-tile__img img {
        transform: scale(1.05);
      }
    }
    &__img {
      margin-bottom: 3rem;
      flex-grow: 1;
      align-items: center;
      display: flex;
      height: 30rem;
      width: 100%;
      justify-content: center;
      img {
        transition: all 0.5s ease;
      }
    }
    &__title {
      margin-top: auto;
    }
    &.card {
      .c-rent-tile__title {
        font-weight: 500;
        max-width: 23.6rem;
        text-align: center;
        margin: auto;
      }
    }
  }
}