.c-price {
  padding-bottom: 4rem;
  &-item {
    border: 2px solid $gray;
    padding: 2rem 3rem 5.6rem;
    min-height: 45rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 6rem;
    position: relative;
    transition: all 0.3s ease;
    @include media-breakpoint-down(sm) {
      min-height: 40rem;
      margin-bottom: 2rem;
    }
    @include media-breakpoint-down(xs) {
      padding: 3rem;
      min-height: auto;
    }
    @include media-breakpoint-down(xx) {
      padding: 2rem;
    }
    &:hover {
      background-color: $light-gray;
      border: 2px solid $light-gray;
      .c-price-item__title {
        color: $dark;
      }
    }
    &__mark {
      display: flex;
      justify-content: flex-end;
      .icon {
        font-size: 6rem;
        @include media-breakpoint-down(sm) {
          font-size: 4rem;
        }
      }
    }
    &__content {
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: center;
    }
    &__title {
      line-height: 3.4rem;
      text-align: center;
      margin-top: 6.4rem;
      color: $dark;
      @include media-breakpoint-down(xs) {
        margin-top: 3rem;
      }
      @include media-breakpoint-down(xx) {
        font-size: 2.4rem;
        line-height: normal;
      }
    }
    &-link {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: auto;
      @include media-breakpoint-down(xs) {
        margin-top: 4rem;
      }
      .icon {
        font-size: 5rem;
        margin-bottom: 1rem;
        @include media-breakpoint-down(sm) {
          font-size: 3rem;
        }
      }
      &__txt {
        font-size: 1.8rem;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 0.02em;
      }
    }
  }
}
