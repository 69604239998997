.c-main-banner {
  background-image: url(../images/useful/main-banner/main-banner-2.jpg);
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  padding: 14rem 0 8rem;
  position: relative;
  @include media-breakpoint-down(md) {
    padding: 12rem 0 8rem;
    background-image: url(../images/useful/main-banner/main-banner-2--md.jpg);
  }
  @include media-breakpoint-down(sm) {
    padding: 10rem 0 5rem;
   }
  @include media-breakpoint-down(xs) {
    background-image: url(../images/useful/main-banner/main-banner-2--xx.jpg);
  }
  @include media-breakpoint-down(xx) {
    padding: 8rem 0 3rem;
  }
  &__track {
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(-90deg);
    right: -11.8rem;
    background-color: $accent;
    padding: 1.85rem 3.4rem;
    @include media-breakpoint-down(xs) {
      display: none;
    }
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    max-width: 50rem;
    padding-top: 6rem;
    width: 100%;
    @include media-breakpoint-down(xs) {
      max-width: 100%;
    }
  }
  &__ico {
    font-size: 10.9rem;
    @include media-breakpoint-down(md) {
      font-size: 8rem;
    }
    @include media-breakpoint-down(xx) {
      font-size: 6rem;
    }
  }
  &__info {
    display: flex;
    margin-top: 3.4rem;
    @include media-breakpoint-down(xx) {
      margin-top: 2.5rem;
    }
  }
  &__subtitle {
    color: white;
    font-weight: 500;
    text-transform: uppercase;
    margin: 0;
    letter-spacing: -.2px;
    @include media-breakpoint-down(xx) {
      font-size: 3rem;
    }
  }
  &__description {
    font-weight: 500;
    line-height: 2rem;
    color: white;
    max-width: 33rem;
    display: inline-flex;
    margin-top: 3rem;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 1.4rem;
    letter-spacing: -.1px;
    @include media-breakpoint-down(xs) {
      max-width: 100%;
    }
  }
  &__img {
    margin-left: 1.2rem;
    margin-top: -5.5rem;
    position: relative;
    z-index: 2;
    @include media-breakpoint-down(xs) {
      display: none;
    }
  }
  &__actions {
    margin-top: 2.4rem;
    @include media-breakpoint-down(xs) {
      display: flex;
      flex-direction: column;
    }
    .btn:not(:last-child) {
      margin-right: 3rem;
      @include media-breakpoint-down(xs) {
        margin-right: 0;
        margin-bottom: 2rem;
      }
    }
  }
  &-video {
    display: flex;
    align-items: center;
    margin-top: 7.2rem;
    margin-left: 1.5rem;
    &:hover {
      .c-main-banner-video__ico:after , .c-main-banner-video__ico:before {
        animation-play-state: paused;
      }
    }
    &__ico {
      height: 5.2rem;
      width: 5.2rem;
      border-radius: 50%;
      background-color: $accent;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .icon {
        position: relative;
        z-index: 5;
      }
      &:after {
        content: "";
        position: absolute;
        height: 6.4rem;
        width: 6.4rem;
        border-radius: 50%;
        background-color: rgba($color: $accent, $alpha: 0.5);
        animation: playWawe 1.5s infinite 1s;
        transition: all 0.3s ease;
      }
      &:before {
        content: "";
        position: absolute;
        height: 7.6rem;
        width: 7.6rem;
        border-radius: 50%;
        background-color: rgba($color: $accent, $alpha: 0.2);
        animation: playWawe 2s infinite 0.5s;
        transition: all 0.3s ease;
      }
    }
    &__txt {
      margin-left: 2.2rem;
      font-weight: bold;
      font-size: 1.8rem;
      letter-spacing: 0.02em;
      color: $accent;
    }
  }
  &__advantages {
    margin-top: 9.4rem;
    @include media-breakpoint-down(md) {
      margin-top: 5rem;
    }
  }
  &-advantage {
    display: flex;
    flex-direction: column;
    max-width: 33rem;
    @include media-breakpoint-down(md) {
      max-width: 100%;
      margin-bottom: 2rem;
    }
    @include media-breakpoint-down(sm) {
     display: none;
    }
    &__title {
      font-weight: 900;
      line-height: 3.4rem;
      color: white;
      @include media-breakpoint-down(md) {
        font-size: 2rem;
        line-height: normal;
        text-align: center;
      }
    }
    &__info {
      margin-top: .4rem;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: white;
      letter-spacing: -.4px;
      @include media-breakpoint-down(md) {
        font-size: 1.4rem;
        line-height: 2rem;
        text-align: center;
      }
    }
  }
}

