.e-input {
  margin: 0;
  width: 100%;
  .form-control {
    border: none;
    border-bottom: 2px solid $dark;
    padding: 0;
    font-size: 1.6rem;
    font-weight: 500;
    color: $dark;
    font-feature-settings: 'pnum' on, 'lnum' on;
    &::placeholder{
      font-size: 1.6rem;
      font-weight: 500;
      color: $dark;
    }
    &:focus , &:active {
      outline: none;
      box-shadow: none;
    }
  }
}