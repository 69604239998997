.c-info-text {
  margin-bottom: 6rem;
  @include media-breakpoint-down(xx) {
    margin-bottom: 2.5rem;
  }
  &__head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    @include media-breakpoint-down(xs) {
      flex-direction: column;
    }
    h2 {
      margin-bottom: 0 !important;
      @include media-breakpoint-down(xs) {
        margin-bottom: 2rem !important;
      }
    }
  }
  h1,h2,h3,h4,h5,h6 {
    margin-bottom: 2rem;
  }
  h2 {
    margin-bottom: 4rem;
  }
  h3 {
    font-size: 2.8rem;
    margin-bottom: 4rem;
  }
  h4 {
    font-size: 2.4rem;
    margin-bottom: 1.2rem;
  }
  a ,span , p , b {
    font-weight: 1.8rem;
    font-weight: 500;
    max-width: 78.2rem;
    @include media-breakpoint-down(sm) {
      font-size: 1.6rem;
    }
    @include media-breakpoint-down(xx) {
      font-size: 1.4rem;
    }
  }
  p , b{
    margin-bottom: 2.9rem;
    display: block;
  }
  a {
    color: $accent;
    font-weight: bold;
  }
  p {
    margin-bottom: 4rem;
    @include media-breakpoint-down(xx) {
      margin-bottom: 2.5rem;
    }
  }
  ul {
    padding-left: 1.6rem;
    margin: 0;
    margin-top: 1rem;
    li {
      margin-bottom: .5rem;
    }
  }
  img {
    margin-bottom: 6rem;
  }
}