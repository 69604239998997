.c-concrete-propose {
  padding-bottom: 5.5rem;
  &__select {
    width: 34.3rem;
    margin-top: 4rem;
  }
  &__list {
    margin-top: 8rem;
    margin-bottom: -8rem;
  }
  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 8rem;
    &__head {
      width: 20.4rem;
      height: 20.4rem;
      border-radius: 50%;
      border: 2px solid $accent;
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      @include media-breakpoint-down(xx) {
        height: 15rem;
        width: 15rem;
      }
      .icon {
        font-size: 6rem;
        margin-bottom: 2rem;
        @include media-breakpoint-down(xx) {
          font-size: 4rem;
        }
      }
    }
    &__title {
      font-feature-settings: 'pnum' on, 'lnum' on;
      font-size: 1.8rem;
      font-weight: bold;
      max-width: 8rem;
      text-align: center;
      line-height: 2.5rem;
      @include media-breakpoint-down(xx) {
        font-size: 1.6rem;
      }
    }
    &__info {
      font-size: 1.8rem;
      font-weight: 500;
      text-align: center;
      line-height: 2.7rem;
      max-width: 34.5rem;
      display: inline-block;
      margin-top: 3.2rem;
      @include media-breakpoint-down(sm) {
        font-size: 1.6rem;
      }
      @include media-breakpoint-down(xx) {
        font-size: 1.4rem;
      }
    }
  }
}