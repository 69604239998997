.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
  }
  
  /* ==========================================
  Single-colored icons can be modified like so:
  .icon-name {
    font-size: 32px;
    color: red;
  }
  ========================================== */
  .icon-logo{
    width: 8em;
  }
  
  .icon-concrete{
    width: 1.0712890625em;
  }
  
  .icon-lease{
    width: 1.478515625em;
  }
  
  .icon-loose{
    width: 1.375em;
  }
  
  .icon-arrow{
    width: 1.25em;
  }
  .icon-arrowtwo{
    width: 1.42857143em;
  }
  .icon-beton {
    width: 4.55045872em;
  }
  
  .icon-pdf {
    width: 0.766666667em;
  }

  .icon-nav , .icon-clock2 {
    color: transparent !important;
  }