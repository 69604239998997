.c-portfolio {
  &-item {
    margin-bottom: 6rem;
    @include media-breakpoint-down(xx) {
      margin-bottom: 2rem;
    }
    &:hover {
      .demo-gallery-poster {
        opacity: 1;
        visibility: visible;
      }
      color: inherit;
    }
    &__img {
      position: relative;
      height: 37.7rem;
      @include media-breakpoint-down(md) {
        height: 30rem;
      }
      @include media-breakpoint-down(xx) {
        height: 20rem;
      }
      .demo-gallery-poster {
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon {
          font-size: 2.03rem;
        }
      }
    }
    &__info {
      display: flex;
      flex-direction: column;
      padding-top: 2rem;
      @include media-breakpoint-down(xx) {
        mask-position: 1rem;
      }
    }
    &__title {
      font-size: 1.8rem;
      font-weight: bold;
      @include media-breakpoint-down(xx) {
        font-size: 1.6rem;
      }
    }
    &__subtitle {
      font-weight: 300;
      margin-top: 1rem;
      @include media-breakpoint-down(xx) {
        margin-top: 0;
      }
    }
  }
}
