.c-contacts {
  &__inner {
    display: flex;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }
  &__content {
    flex: 0 0 100%;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
  &__map {
    height: 62rem;
    width: 60%;
    flex: 0 0 60%;
    transform: translateX(-100%);
    @media only screen and (min-width: 2000px) {
      width: 55%;
      flex: 0 0 55%;
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
      transform: none;
      flex: none;
      height: 40rem;
      margin-top: 5rem;
    }
  }
  &-item {
    display: flex;
    max-width: 29.3rem;
    align-items: flex-start;
    &:not(:last-child){
      margin-bottom: 2.5rem;
    }
    &__ico {
      display: flex;
      justify-content: center;
      .icon {
        color: $accent;
        font-size: 3.5rem;
      }
    }
    &__info {
      display: flex;
      flex-direction: column;
      margin-left: 1.5rem;

    }
    &__title {
      font-weight: 500;
      color: $accent;
      font-size: 1.8rem;
      font-feature-settings: 'pnum' on, 'lnum' on;
    }
    &__text {
      font-size: 1.8rem;
      font-feature-settings: 'pnum' on, 'lnum' on;
    }
  }
  &__list {
    margin-top: 10rem;
  }
}