.dropdown {
  &-toggle {
    line-height: normal;
    display: flex;
    align-items: center;
    transition: all .2s ease;
    &:after {
      display: none;
    }
  }
  .icon-arrow {
    font-size: .75rem;
    margin-left: .3rem;
  }
  &:hover {
    .dropdown-toggle {
      color: $accent;
    }
  }
  &.show {
    .icon-arrow {
      transform: rotateX(180deg);
    }
  }
  &-menu {
    padding: 0.5rem 0;
    border: .1rem solid $gray;
    border-radius: .2rem;
    min-width: 6rem;
    &.show {
      top: 1rem !important;
      left: auto !important;
      right: 0;
    }
  }
  &-item {
    font-size: 1.4rem;
    color: $dark;
    padding: 0.5rem 1rem;
    transition: all .2s ease;
    &:hover {
      color: $accent;
      background-color: inherit;
    }
  }
}