.c-header {
  padding: 4.3rem 0;
  position: relative;
  background-color: $light-gray;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  // transition: all 0.6s ease;
  &.fixed {
    padding: 1.9rem 0;
    z-index: 999;
    position: fixed;
    .c-header__logo .icon-logo {
      width: 20rem;
      height: 2.5rem;
      @include media-breakpoint-down(xx) {
        width: 10rem;
      }
    }
    @include media-breakpoint-down(xx) {
      padding: 1rem 0;
    }
  }
  @include media-breakpoint-down(md) {
    padding: 2rem 0;
  }
  @include media-breakpoint-down(xx) {
    padding: 1rem 0;
  }
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__actions {
    display: flex;
    align-items: center;
  }
  &__logo {
    .icon-logo {
      width: 20rem;
      height: 4rem;
      @include media-breakpoint-down(xx) {
        width: 12rem;
      }
    }
  }
  &-nav {
    @include media-breakpoint-down(md) {
      display: none;
    }
    &__list {
      display: flex;
    }
    &-item {
      margin-right: 3.5rem;
      &__link {
        display: flex;
        align-items: center;
        transition: all 0.2s ease;
        .icon {
          font-size: 2.6rem;
        }
        .icon-truck-tow {
          font-size: 3.6rem;
        }
      }
      &__txt {
        margin-left: 1.2rem;
        max-width: 8.5rem;
        line-height: 1.6rem;
      }
    }
  }
  &-phone {
    display: flex;
    align-items: center;
    margin-right: 3.2rem;
    transition: all 0.2s ease;
    @include media-breakpoint-down(md) {
      display: none;
    }
    .icon-phone {
      font-size: 3rem;
    }
    &__info {
      display: flex;
      flex-direction: column;
      margin-left: 1rem;
    }
    &__txt {
      line-height: 2rem;
      font-feature-settings: "pnum" on, "lnum" on;
    }
  }
  &__language {
    display: flex;
    align-items: center;
    margin-right: 4.5rem;
    @include media-breakpoint-down(xx) {
      margin-right: 3rem;
    }
  }
  &-menu-btn {
    display: flex;
    align-items: center;
    width: 4rem;
    height: 1.8rem;
    display: flex;
    align-items: center;
    @include media-breakpoint-down(xx) {
      width: 3rem;
    }
    &:hover {
      .c-header-menu-btn__line {
        background-color: $accent;
        &:after,
        &:before {
          background-color: $accent;
        }
      }
    }
    &__line {
      background-color: $dark;
      position: relative;
      width: 100%;
      height: 0.2rem;
      display: inline-flex;
      transition: all 0.2s ease;
      &:after,
      &:before {
        transition: all 0.2s ease;
        content: "";
        position: absolute;
        background-color: $dark;
        height: 0.2rem;
        width: 100%;
        left: 0;
        right: 0;
      }
      &:before {
        top: -0.6rem;
      }
      &:after {
        bottom: -0.6rem;
      }
    }
  }
  &.above-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    .c-header-nav-item__link,
    .c-header-phone,
    .c-header__language .dropdown-toggle {
      color: white;
    }
    .c-header-menu-btn__line,
    .c-header-menu-btn__line:after,
    .c-header-menu-btn__line:before {
      background-color: white;
    }
    .c-header__inner {
      z-index: 10;
      position: relative;
    }
    &.fixed {
      background-color: $light-gray;
      padding: 1.9rem 0;
      position: fixed;
      z-index: 999;
      .c-header-nav-item__link,
      .c-header-phone,
      .c-header__language .dropdown-toggle {
        color: $dark;
      }
      .c-header-menu-btn__line,
      .c-header-menu-btn__line:after,
      .c-header-menu-btn__line:before {
        background-color: $dark;
      }
      .c-header__logo .icon-logo {
        width: 20rem;
        height: 2.5rem;
        @include media-breakpoint-down(xx) {
          width: 10rem;
        }
      }
      @include media-breakpoint-down(xx) {
        padding: 1rem 0;
      }
    }
  }
  &-menu {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: $dark;
    height: 100vh;
    z-index: 999;
    display: flex;
    flex-direction: column;
    padding: 5rem 13.5rem;
    transform: translateX(100%);
    transition: all 0.4s ease-in-out;
    opacity: 0;
    width: 50%;
    @include media-breakpoint-down(lg) {
      padding: 5rem;
    }
    @include media-breakpoint-down(md) {
      width: 100vw;
    }
    @include media-breakpoint-down(xx) {
      padding: 2rem;
    }
    &.show {
      opacity: 1;
      transform: translateX(0);
    }
    &__list {
      counter-reset: counter;
      
    }
    &__close {
      position: absolute;
      right: 4rem;
      top: 5rem;
      @include media-breakpoint-down(xx) {
        top: 2rem;
      }
      &:hover {
        .icon-close {
          color: $accent;
        }
      }
      .icon-close {
        transition: all 0.3s ease;
        color: white;
      }
    }
    &__wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex-grow: 1;
    }
    &__link {
      font-weight: 500;
      color: white;
      font-size: 4rem;
      line-height: 5.6rem;
      @include media-breakpoint-down(xs) {
        font-size: 3rem;
      }
      @include media-breakpoint-down(xx) {
        font-size: 2rem;
        line-height: normal;
      }
    }
    &__nav {
      flex-grow: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 5rem 0;
      @include media-breakpoint-down(xx) {
        padding: 2rem 0;
      }
    }
    &__item {
      position: relative;
      display: flex;
      align-items: center;
      &:before {
        counter-increment: counter;
        content: "0" counters(counter, ".");
        font-weight: bold;
        font-size: 4.2rem;
        font-feature-settings: "pnum" on, "lnum" on;
        color: $dark-gray;
        margin-right: 3.4rem;
        transition: all 0.3s ease;
        @include media-breakpoint-down(xs) {
          font-size: 3.2rem;
        }
        @include media-breakpoint-down(xx) {
          font-size: 2.4rem;
          line-height: normal;
        }
      }
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
      &.active {
        &:before {
          color: $accent;
        }
        .c-header-menu__link {
          color: $accent;
        }
      }
      &:hover:before {
        color: $accent;
      }
    }
    &__link {
      transition: all 0.3s ease;
    }
  }
}
