.c-modal-comment {
  .modal {
    &-dialog {
      max-width: 75.2rem;
    }
  }
  &__inner {
    padding: 3rem 8rem 2rem 6.6rem;
    display: flex;
    align-items: center;
    @include media-breakpoint-down(xx) {
      padding: 3rem 6rem 3rem 3rem;
     }
  }
  &__img {
    margin-right: 4.7rem;
    @include media-breakpoint-down(xs) {
      display: none;
     }
  }
  &__title {
    color: $accent;
    font-weight: 900;
    font-size: 4rem;
    text-transform: uppercase;
    max-width: 33rem;
    @include media-breakpoint-down(xx) {
      font-size: 2rem;
      line-height: 3rem;
     }
  }
}