.c-pagination {
  display: flex;
  justify-content: center;
  padding-bottom: 6rem;
  &__list {
    display: flex;
  }
  &__nav {
    display: flex;
    align-items: center;
  }
  &__link {
    font-weight: 500;
    font-feature-settings: "pnum" on, "lnum" on;
    display: inline-flex;
    min-width: 4rem;
    padding: 0.8rem 1rem;
    height: 4rem;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    background-color: #fff;
    @include media-breakpoint-down(xx) {
      min-width: 3rem;
      padding: 0.8rem;
      height: 3rem;
    }
  }
  &__item {
    margin-right: .6rem;
    @include media-breakpoint-down(xx) {
      margin-right: .3rem;
    }
    &:first-child {
      margin-left: .6rem;
      @include media-breakpoint-down(xx) {
        margin-left: .3rem;
      }
    }
    &:hover {
      .c-pagination__link {
        background-color: $accent;
        color: white;
      }
    }
    &--disabled {
      pointer-events: none;
    }
    &--active {
      .c-pagination__link {
        background-color: $accent;
        color: white;
      }
    }
  }
  &__btn {
    min-width: 4rem;
    padding: 0.8rem 1rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    @include media-breakpoint-down(xx) {
      min-width: 3rem;
      padding: 0.8rem;
      height: 3rem;
    }
    .icon {
      transition: all 0.3s ease-in-out;
      font-size: 1.4rem;
    }
    &:hover {
      .icon {
        color: $accent;
      }
    }
    &--prev {
      transform: rotate(-180deg);
    }
    &--two {
      &:hover {
        .icon {
          color: $accent;
        }
      }
      &--next {
        transform: rotate(-180deg);
      }
    }
  }
}
