.bootstrap-select {
  .dropdown-toggle {
    background-color: $light-gray !important;
    position: relative;
    padding: 1.5rem 1rem;
    border: none;
    border-bottom: 2px solid $dark;
    &:focus {
      outline: none !important;
      outline-offset: 0;
      box-shadow: none !important;
    }
    &::after {
      content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xLjQxIDAuNTkwMDI2TDYgNS4xNzAwM0wxMC41OSAwLjU5MDAyN0wxMiAyLjAwMDAzTDYgOC4wMDAwM0wtNi4xNjMzMWUtMDggMi4wMDAwM0wxLjQxIDAuNTkwMDI2WiIgZmlsbD0iIzBBMEEwQSIvPgo8L3N2Zz4K);
      transform: translateY(-50%);
      display: inline-block;
      margin-left: 0.255em;
      position: absolute;
      right: 2.2rem;
      border: none;
      top: 50%;
      transition: all 0.3s ease;
    }
    .filter-option-inner-inner {
      font-size: 1.6rem;
      text-transform: capitalize;
      color: $dark;
      font-weight: 500;
    }
  }
  &.show {
    .dropdown-toggle:after {
      transform: rotateX(180deg) translateY(45%);
    }
  }
  .dropdown-menu.show {
    top: 0 !important;
    border-top: 0;
    border-radius: 0;
    .dropdown-item {
      padding: 1rem;
    }
    .dropdown-item:active {
      background-color: transparent;
      color: $accent;
    }
    .dropdown-item.active {
      background-color: $accent;
      &:active {
        color: white;
      }
    }
    .dropdown-item:hover,
    .dropdown-item:focus {
      color: $accent;
      outline: none;
    }
    .dropdown-item.active:hover,
    .dropdown-item.active:focus {
      color: white;
      outline: none;
    }
  }
}
