.c-feedback {
  @include media-breakpoint-down(xs) {
    padding-top: 3rem;
  }
  &__inner {
    display: flex;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }
  &__map {
    height: 62rem;
    width: 50%;
    flex: 0 0 50%;
    transform: translateX(-100%);
    @include media-breakpoint-down(sm) {
      width: 100%;
      transform: none;
      flex: none;
      height: 40rem;
      margin-top: 5rem;
    }
  }
  &__content {
    flex: 0 0 100%;
   
  }
  &__form {
    margin-top: 9.3rem;
    max-width: 43.6rem;
    @include media-breakpoint-down(sm) {
      max-width: 100%;
      margin-top: 3rem;
    }
    .form-line {
      display: flex;
      margin-bottom: 2.7rem;
      .e-input:not(:last-child) {
        margin-right: 4rem;
      }
      .half-width {
        width: 19.8rem;
        @include media-breakpoint-down(sm) {
          width: 47%;
        }
        @include media-breakpoint-down(xs) {
          width: 100%;
        }
      }
    }
    .btn {
      margin-top: 0.7rem;
      padding: 1.85rem 5.6rem;
    }
  }
  &.wide {
    padding-bottom: 10rem;
    .form-actions {
      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }
    }
    .e-section-title {
      font-size: 3rem;
    }
    .c-feedback__form {
      max-width: 100%;
      margin-top: 5rem;
      .form-actions {
        display: flex;
      }
    }
    .form-group:not(:last-child){
      margin-right: 4rem;
      max-width: 19.8rem;
      @include media-breakpoint-down(sm) {
        max-width: 100%;
        margin-bottom: 3rem;
        margin-right: 0;
      }
    }
    .form-group .e-input {
      @include media-breakpoint-down(sm) {
        margin-bottom: 2rem;
        max-width: 100%;
        margin-bottom: 3rem;
        margin-right: 0;
      }
    }
  }
  .c-feedback__form .btn {
    margin-top: 4.4rem;
  }
}
