.c-track-order {
  background-color: $dark;
  padding-bottom: 16.7rem;
  @include media-breakpoint-down(sm) {
    padding-bottom: 8rem;
  }
  &__inner {
    display: flex;
    margin-top: 8rem;
    position: relative;
    justify-content: center;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      margin-top: 6rem;
    }
    &:before {
      content: attr(data-text);
      position: absolute;
      top: 26px;
      right: -25rem;
      font-size: 14.4rem;
      color: white;
      opacity: 0.07;
      font-weight: 900;
      line-height: normal;
      z-index: 0;
      text-transform: uppercase;
      @include media-breakpoint-down(md) {
        font-size: 10rem;
        opacity: 0.03;
      }
    }
    &:after {
      content: attr(data-text);
      position: absolute;
      bottom: -17px;
      left: -37rem;
      font-size: 14.4rem;
      color: white;
      opacity: 0.07;
      font-weight: 900;
      line-height: normal;
      text-transform: uppercase;
      @include media-breakpoint-down(md) {
        font-size: 10rem;
        opacity: 0.03;
      }
    }
  }
  &__col {
    @include media-breakpoint-down(sm) {
      margin-bottom: 5rem;
    }
  }
  &__title {
    max-width: 21rem;
    color: $accent;
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 3.6rem;
    margin-bottom: 2.5rem;
    display: inline-block;
    margin-top: 7rem;
    @include media-breakpoint-down(sm) {
      max-width: 100%;
      display: block;
      text-align: center;
      margin-top: 0;
    }
  }
  &__desc {
    max-width: 35rem;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: white;
    margin-bottom: 2.5rem;
    @include media-breakpoint-down(sm) {
      max-width: 100%;
      text-align: center;
    }
  }
  &__img {
    margin: 0 8rem;
    position: relative;
    z-index: 2;
    @include media-breakpoint-down(md) {
      margin: 0 4rem;
    }
    @include media-breakpoint-down(sm) {
      display: flex;
      justify-content: center;
    }
  }
  &__col:first-child {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @include media-breakpoint-down(sm) {
      align-items: flex-start;
    }
    .c-track-order__title , .c-track-order__desc{
      text-align: right;
      margin-left: auto;
      @include media-breakpoint-down(sm) {
        text-align: center;
        width: 100%;
        margin-left: 0;
      }
    }
  }
}